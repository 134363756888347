<template>
  <base-dialog
    ref="dialog"
    max-width="650"
    :title="$t('message.invoice_bulk_editing')"
    :cancel-text="$t('message.modal_cancel_btn')"
    :submit-text="$t('message.save')"
    @submit="submit()"
  >
    <template v-slot:card-text>
      <v-form ref="form">
        <v-container>
          <v-row>
            <v-col cols="3">
              <div class="pt-4 pl-4" style="font-size: 12px">支払日付</div>
            </v-col>
            <v-col cols="4">
              <v-menu
                class="mx-1"
                :close-on-content-click="true"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
              >
                <template #activator="{ on }">
                  <v-text-field
                    v-model="value"
                    placeholder="YYYY-MM-DD"
                    tabindex=""
                    readonly
                    dense
                    outlined
                    filled
                    :rules="[(v) => !!v || '※この項目は必須です']"
                    v-on="on"
                  />
                </template>

                <v-date-picker
                  v-model="value"
                  locale="jp-ja"
                  :day-format="(date) => new Date(date).getDate()"
                />
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
  </base-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      value: '',
    }
  },
  methods: {
    open() {
      this.$refs.dialog.open()
      this.loading = false
    },
    close() {
      this.$refs.dialog.close()
      this.loading = false
    },
    stopload() {
      this.loading = false
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.$emit('submit', this.value)
      }
    },
  },
}
</script>
