<template>
  <base-dialog
    ref="dialog"
    :title="'ステータス'"
    :cancel-text="$t('message.modal_cancel_btn')"
    :submit-text="'選択したデータを表示'"
    max-width="800"
    @submit="onClick()"
  >
    <template v-slot:card-text>
      <v-layout row px-3 pt-3>
        <v-select
          v-model="dateFilter.initialDate"
          :items="dateItems"
          single-line
          class="flat-select-box px-3 pt-0 fw-bold"
          style="max-width: 150px"
        />
        <span class="px-3 pt-2">〜</span>

        <v-select
          v-model="dateFilter.finalDate"
          :items="dateItems"
          single-line
          class="flat-select-box px-3 pt-0 fw-bold"
          style="max-width: 150px"
        />

        <v-checkbox
          v-model="dateFilter.show"
          :label="'日付なしを表示する'"
          color="primary"
          class="pt-0 ml-4 mt-2"
        />
      </v-layout>

      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="statusData"
        item-key="pay_date"
        hide-default-footer
        :items-per-page="9999"
        class="sweeep-status-table px-3 pb-4"
        :customFilter="customFilter"
        :search="dateFilter"
      >
        <template v-slot:item.pay_date="{ item }">
          <span v-if="item.pay_date">
            {{ item.pay_date }}
          </span>
          <span v-else> 日付なし </span>
        </template>

        <template v-slot:item.monthTotal="{ item }">
          <div
            class="status-data-cell"
            @click="onSelect(item, 'monthTotal')"
            @dblclick="onClick"
            :style="setBackGround(item.monthTotal.selected)"
          >
            {{ item.monthTotal.count }}
          </div>
        </template>

        <template v-slot:item.notPaid="{ item }">
          <div
            class="status-data-cell"
            @click="onSelect(item, 'notPaid')"
            @dblclick="onClick"
            :style="setBackGround(item.notPaid.selected)"
          >
            {{ item.notPaid.count }}
          </div>
        </template>

        <template v-slot:item.paid="{ item }">
          <div
            class="status-data-cell"
            @click="onSelect(item, 'paid')"
            @dblclick="onClick"
            :style="setBackGround(item.paid.selected)"
          >
            {{ item.paid.count }}
          </div>
        </template>

        <template v-slot:item.notExported="{ item }">
          <div
            class="status-data-cell"
            @click="onSelect(item, 'notExported')"
            @dblclick="onClick"
            :style="setBackGround(item.notExported.selected)"
          >
            {{ item.notExported.count }}
          </div>
        </template>

        <template v-slot:item.exported="{ item }">
          <div
            class="status-data-cell"
            @click="onSelect(item, 'exported')"
            @dblclick="onClick"
            :style="setBackGround(item.exported.selected)"
          >
            {{ item.exported.count }}
          </div>
        </template>
      </v-data-table>
    </template>
  </base-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import Snackbar from 'Helpers/snackbar/index'
import axios from 'axios'
import Vue from 'vue'
export default {
  props: {
    statusData: Array,
    selectedItem: Object,
  },
  data() {
    return {
      dialog: false,
      headers: [
        { width: 20, text: '支払月', value: 'pay_date', sortable: false },
        { width: 100, text: '読取済み', value: 'allInvoice', sortable: false },
        { width: 100, text: '未払', value: 'notPaid', sortable: false },
        { width: 100, text: '支払済み', value: 'paid', sortable: false },
        {
          width: 100,
          text: '仕訳未出力',
          value: 'notExported',
          sortable: false,
        },
        { width: 100, text: '仕訳出力済', value: 'exported', sortable: false },
      ],
      selected: [],
      dateItems: [],
      dateFilter: {
        initialDate: null,
        finalDate: null,
        show: true,
      },
    }
  },
  watch: {
    dateFilter: {
      handler: function (val, oldVal) {
        localStorage.setItem('paymentStatus', JSON.stringify(this.dateFilter))
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {
    open() {
      this.$refs.dialog.open()
      this.statusData.forEach((v) => {
        if (v.pay_date !== '') this.dateItems.push(v.pay_date)
      })
      this.loadLocalStorage()
    },
    close() {
      this.clearAllSelect().then((result) => {
        this.$refs.dialog.close()
      })
    },
    setBackGround(selected) {
      return selected ? 'background-color:rgb(220,220,255) !important' : ''
    },
    clearAllSelect() {
      try {
        this.selectedItem.date = ''
        this.selectedItem.name = ''
        this.selectedItem.count = ''

        var statusData = this.statusData
        var names = ['notPaid', 'paid', 'notExported', 'exported']

        return new Promise(function (resolve) {
          for (var item of statusData) {
            for (var name of names) {
              item[name].selected = false
            }
          }
          resolve(true)
        })
      } catch (e) {
        console.log(e)
      }
    },
    onSelect(item, name) {
      if (item.pay_date && item[name].count) {
        this.clearAllSelect().then((result) => {
          item[name].selected = true
          this.selectedItem.date = item.pay_date
          this.selectedItem.name = name
          this.selectedItem.count = item[name].count
        })
      }
    },
    onClick() {
      if (this.selectedItem.name) {
        this.$emit('onClick')
        this.close()
      } else {
        Snackbar.error('データを選択してください')
      }
    },
    customFilter(items, search) {
      return items.filter((item) => {
        if (item.pay_date === '') {
          return search.show
        }
        let payDate = new Date(
          item.pay_date.split('-')[0],
          item.pay_date.split('-')[1]
        )
        try {
          if (search.initialDate !== null) {
            let initialDate = new Date(
              search.initialDate.split('-')[0],
              search.initialDate.split('-')[1]
            )
            if (search.finalDate !== null) {
              let finalDate = new Date(
                search.finalDate.split('-')[0],
                search.finalDate.split('-')[1]
              )
              return payDate >= initialDate && payDate <= finalDate
            } else {
              return payDate >= initialDate
            }
          }
          if (search.finalDate !== null) {
            let finalDate = new Date(
              search.finalDate.split('-')[0],
              search.finalDate.split('-')[1]
            )
            return finalDate >= payDate
          }
        } catch (e) {
          console.log(e)
        }
      })
    },
    loadLocalStorage() {
      let storageFilter = localStorage.getItem('paymentStatus')
      if (storageFilter == undefined) {
        this.dateFilter.initialDate = this.statusData[1].pay_date
        this.dateFilter.finalDate = this.statusData[11].pay_date
        this.dateFilter.show = true
        localStorage.setItem('paymentStatus', JSON.stringify(this.dateFilter))
      } else {
        storageFilter = JSON.parse(storageFilter)
        this.dateFilter.initialDate = storageFilter.initialDate
        this.dateFilter.finalDate = storageFilter.finalDate
        this.dateFilter.show = storageFilter.show
      }
    },
  },
}
</script>
