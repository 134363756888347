<template>
  <v-dialog v-model="dialog" max-width="350">
    <v-card class="sweeep-dialog">
      <v-card-title v-text="$t('message.invoice_list_export')" />

      <v-card-text>
        <v-form ref="form">
          <div class="mb-5">
            {{ numOfData }}件の支払仕訳をダウンロードします。
          </div>

          <base-alert v-if="formatList.length <= 0" color="red" class="mt-2">
            表示可能なフォーマット設定がありません。<br />
            （管理者設定メニューで表示設定を変更できます。）
          </base-alert>

          <v-autocomplete
            v-model="formatId"
            :items="formatList"
            label="ファイルフォーマット"
            dense
            outlined
            filled
            class="small-input"
            :rules="rules"
            item-text="name"
            item-value="id"
          />
          <v-select
            v-model="encodeType"
            :items="encodeTypes"
            label="文字コード"
            dense
            outlined
            filled
            class="small-input"
            :rules="rules"
          />
          <v-row class="px-4 mb-3">
            <span>
              <v-checkbox
                v-model="withHeader"
                v-tooltip="'チェックをOffにするとヘッダ行を出力しません'"
                label="ヘッダを出力する"
                color="primary"
                class="small-checkbox"
                hide-details
              />
            </span>
          </v-row>

          <v-row class="px-4 mb-3">
            <v-checkbox
              v-model="isQuoteAll"
              v-tooltip="'チェックをOnにすると全ての項目をダブルコーテーション囲みます'"
              label="全てをダブルコーテーションで囲む"
              color="primary"
              hide-details
            />
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer />
        <v-btn @click.native="close">
          {{ $t('message.modal_cancel_btn') }}
        </v-btn>
        <v-btn :loading="loading" color="primary" @click="submit">
          {{ $t('message.payment_table_export') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    numOfData: {
      type: Number,
      required: true,
    },
    formatList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      encodeTypes: ['Shift-JIS', 'utf-8'],
      formatId: '',
      encodeType: 'Shift-JIS',
      withHeader: true,
      isQuoteAll: false,
      rules: [(v) => !!v || '※この項目は必須です'],
      loading: false,
      fileType: 'txt',
    }
  },
  watch: {
    formatList: function () {
      if (this.formatList.length > 0) {
        this.formatId = this.formatList[0].id
      }
    },
  },
  methods: {
    open() {
      this.stopload()
      this.dialog = true
    },
    close() {
      this.stopload()
      this.dialog = false
    },
    startload() {
      this.loading = true
    },
    stopload() {
      this.loading = false
    },
    submit() {
      if (this.$refs.form.validate()) {
        // exportPayment
        this.startload()
        this.$emit('submit', {
          formatId: this.formatId,
          encodeType: this.encodeType,
          withHeader: this.withHeader,
          isQuoteAll: this.isQuoteAll,
        })
      }
    },
  },
}
</script>
