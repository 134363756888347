<template>
  <div>
    <dialog-edit-transfer-fee
      ref="dialogEditTransferFee"
      v-model="editingtransferFee"
      @submit="
        $emit('editTransferFee', {
          transfer_fee: editingtransferFee,
          pay_key: editingItem.pay_key,
        })
      "
    />

    <v-data-table
      :id="tableId"
      ref="paymentTable"
      :headers="computedHeaders"
      :items="computedItems"
      :items-per-page="-1"
      fixed-header
      disable-sort
      hide-default-footer
      class="sweeep-invoicetable px-4 mb-3"
      :height="height"
    >
      <!-- header -->
      <template v-slot:header.selected="{}">
        <select-all-header-cell
          :items="filteredItems"
          compute-value-with-items
          @click:item="onClickSelectAll($event.value, $event.message)"
        />
      </template>

      <template v-slot:header.id="{ header }">
        <header-cell
          :title="header.text"
          :sort-value="header.value"
          :sort-params="sortParams"
          @click="onClickSort"
        />
      </template>

      <template v-slot:header.pay_date="{ header }">
        <header-cell
          :title="header.text"
          :sort-value="header.value"
          :sort-params="sortParams"
          @click="onClickSort"
        />
      </template>

      <template v-slot:header.company_name="{ header }">
        <header-cell
          :title="header.text"
          :sort-value="header.value"
          :sort-params="sortParams"
          @click="onClickSort"
        />
      </template>

      <template v-slot:header.pay_type="{ header }">
        <header-cell
          :title="header.text"
          :sort-value="header.value"
          :sort-params="sortParams"
          @click="onClickSort"
        />
      </template>

      <template v-slot:header.invoice_amount="{ header }">
        <header-cell
          :title="header.text"
          :sort-value="header.value"
          :sort-params="sortParams"
          @click="onClickSort"
        />
      </template>

      <template v-slot:header.pay_amount="{ header }">
        <header-cell
          :title="header.text"
          :sort-value="header.value"
          :sort-params="sortParams"
          @click="onClickSort"
        />
      </template>

      <template v-slot:header.gensen_amount="{ header }">
        <header-cell
          :title="header.text"
          :sort-value="header.value"
          :sort-params="sortParams"
          @click="onClickSort"
        />
      </template>

      <template v-slot:header.transfer_fee="{ header }">
        <header-cell
          :title="header.text"
          :sort-value="header.value"
          :sort-params="sortParams"
          @click="onClickSort"
        />
      </template>

      <template v-slot:header.pay_amount_deduct_transfer_fee="{ header }">
        <header-cell
          :title="header.text"
          :sort-value="header.value"
          :sort-params="sortParams"
          @click="onClickSort"
        />
      </template>

      <template v-slot:header.bank_code="{ header }">
        <header-cell
          :title="header.text"
          :sort-value="header.value"
          :sort-params="sortParams"
          @click="onClickSort"
        />
      </template>

      <template v-slot:header.user_bank="{ header }">
        <header-cell
          :title="header.text"
          :sort-value="header.value"
          :sort-params="sortParams"
          @click="onClickSort"
        />
      </template>

      <template v-slot:header.paid="{ header }">
        <header-cell
          :title="header.text"
          :sort-params="sortParams"
          :sort-items="[
            {
              text: '支払日時で昇順',
              icon: 'feather-arrow-up',
              value: 'date_paid',
              mode: 'asc',
            },
            {
              text: '支払日時で降順',
              icon: 'feather-arrow-down',
              value: 'date_paid',
              mode: 'desc',
            },
            {
              text: '支払ユーザで昇順',
              icon: 'feather-arrow-up',
              value: 'paid_user',
              mode: 'asc',
            },
            {
              text: '支払ユーザで降順',
              icon: 'feather-arrow-down',
              value: 'paid_user',
              mode: 'desc',
            },
          ]"
          @click="onClickSort"
        />
      </template>

      <template v-slot:header.pay_export_count="{ header }">
        <header-cell
          :title="header.text"
          :sort-params="sortParams"
          :sort-items="[
            {
              text: 'エクスポート日時で昇順',
              icon: 'feather-arrow-up',
              value: 'date_export',
              mode: 'asc',
            },
            {
              text: 'エクスポート日時で降順',
              icon: 'feather-arrow-down',
              value: 'date_export',
              mode: 'desc',
            },
            {
              text: 'エクスポートユーザで昇順',
              icon: 'feather-arrow-up',
              value: 'export_user',
              mode: 'asc',
            },
            {
              text: 'エクスポートユーザで降順',
              icon: 'feather-arrow-down',
              value: 'export_user',
              mode: 'desc',
            },
            {
              text: 'エクスポート回数で昇順',
              icon: 'feather-arrow-up',
              value: 'export_count',
              mode: 'asc',
            },
            {
              text: 'エクスポート回数で降順',
              icon: 'feather-arrow-down',
              value: 'export_count',
              mode: 'desc',
            },
          ]"
          @click="onClickSort"
        />
      </template>

      <!-- body -->
      <template v-slot:item.selected="{ item }">
        <v-checkbox
          :input-value="item.selected"
          hide-details
          @change="onClickSelectOne(item, $event)"
        />
      </template>

      <template v-slot:item.id="{ item }">
        <div class="text-right" style="min-width: 60px">
          <span
            class="has-link"
            v-bind="{ disabled: $store.getters.loadedCount < 9 }"
            @click="$emit('click:get-detail', item)"
          >
            {{ item.id }}
          </span>
        </div>
      </template>

      <template v-slot:item.pay_date="{ item }">
        <div
          v-tooltip="item.pay_date"
          class="text-center"
          style="min-width: 55px"
        >
          {{ item.pay_date.slice(5, 10).replace('-', '/') }}
        </div>
      </template>

      <template v-slot:item.company_name="{ item }">
        <div style="min-width: 180px">
          {{ item.company_name }}
        </div>
      </template>

      <template v-slot:item.pay_type="{ item }">
        <div class="text-center" style="min-width: 50px">
          <s-chip
            :color="convertPaymentType(item.pay_type).twColor"
            class="px-2"
          >
            {{ convertPaymentType(item.pay_type).content }}
          </s-chip>
        </div>
      </template>

      <template v-slot:item.bank_account_type="{ item }">
        <div class="text-center" style="min-width: 50px">
          <s-chip
            v-if="item.bank_account_type"
            :color="item.bank_account_type == '普通' ? 'blue-500' : 'green-500'"
            class="px-2"
          >
            {{ item.bank_account_type }}
          </s-chip>
        </div>
      </template>

      <template v-slot:item.bank_account_number="{ item }">
        <div style="min-width: 60px">
          {{ item.bank_account_number }}
        </div>
      </template>

      <template v-slot:item.bank_account_name="{ item }">
        <div style="min-width: 100px">
          {{ item.bank_account_name }}
        </div>
      </template>
      <template v-slot:item.user_bank="{ item }">
        <div style="min-width: 150px; max-width: 150px">
          <span>
            {{ item.user_bank.bank_name }}
          </span>
          <span class="ml-2">
            {{ item.user_bank.bank_account_number }}
          </span>
        </div>
      </template>

      <template v-slot:item.invoice_amount="{ item }">
        <div class="text-right" style="min-width: 70px">
          {{ item.invoice_amount | currency }}
        </div>
      </template>

      <template v-slot:item.pay_amount="{ item }">
        <div class="text-right" style="min-width: 70px">
          {{ item.pay_amount | currency }}
        </div>
      </template>

      <template v-slot:item.gensen_amount="{ item }">
        <div class="text-right" style="min-width: 70px">
          {{ item.gensen_amount | currency }}
        </div>
      </template>

      <template v-slot:item.transfer_fee="{ item }">
        <div style="min-width: 70px" class="d-flex justify-space-between">
          <span v-if="item.paid_by_customer && editable">
            <span v-if="!item.paid_user" @click="openEditTransferFee(item)">
              <s-icon
                :tooltip="$t('message.payment_edit_transfer_fee')"
                icon="feather-edit"
              />
            </span>
            <span v-if="item.paid_user">
              <s-icon color="gray-400" icon="feather-edit" />
            </span>
          </span>
          <span v-else />
          <span class="text-right">
            {{ item.transfer_fee | currency }}
          </span>
        </div>
      </template>

      <template v-slot:item.pay_amount_deduct_transfer_fee="{ item }">
        <div class="text-right" style="min-width: 70px">
          {{ item.pay_amount_deduct_transfer_fee | currency }}
        </div>
      </template>

      <template v-slot:item.bank_code="{ item }">
        <div
          v-tooltip="
            item.bank_code +
            ' ' +
            item.bank_name +
            '<br>' +
            item.bank_branch_code +
            ' ' +
            item.bank_branch_name +
            '<br>' +
            item.bank_account_type +
            ' ' +
            item.bank_account_number +
            '<br>' +
            item.bank_account_name
          "
          style="min-width: 70px; max-width: 180px"
        >
          <span>
            {{ item.bank_name }}
          </span>
        </div>
      </template>

      <template v-slot:item.paid="{ item }">
        <div class="text-center status-avatars" style="min-width: 45px">
          <v-avatar
            v-tooltip="
              '支払<br>' +
              formatDate(item.date_paid) +
              '<br>' +
              showName(item.paid_user)
            "
            size="22"
            class="my-1"
            :class="item.paid_user ? 'green' : 'border'"
          >
            <span class="white--text fs-10">{{
              showName(item.paid_user).slice(0, 2)
            }}</span>
          </v-avatar>
        </div>
      </template>

      <template v-slot:item.pay_export_count="{ item }">
        <div class="text-center status-avatars" style="min-width: 45px">
          <v-badge
            v-tooltip="
              '支払仕訳エクスポート' +
              item.export_count +
              '回<br>' +
              formatDate(item.date_export) +
              '<br>' +
              showName(item.export_user)
            "
            bordered
            inline
            bottom
            color="grey"
            class="pa-0 fw-bold"
            :content="item.export_count"
          >
            <v-avatar size="22" :class="item.export_user ? 'green' : 'border'">
              <span class="white--text fs-10">{{
                showName(item.export_user).slice(0, 2)
              }}</span>
            </v-avatar>
          </v-badge>
        </div>
      </template>

      <v-alert slot="no-results" :value="true" color="info" icon="warning">
        {{ $t('message.noResult') }}
      </v-alert>
    </v-data-table>

    <v-toolbar flat height="60" style="border-top: 1px solid #ccc">
      <v-col cols="8" class="pa-0">
        <v-card flat tile class="d-flex align-content-center flex-wrap">
          <v-card flat class="align-self-center">
            <div class="fs-10 mb-1">表示 / 検索結果</div>
            <v-chip small label class="px-4">
              {{ startIndex + 1 + '-' + endIndex }} / {{ filteredItems.length }}
            </v-chip>
          </v-card>

          <template>
            <v-card flat class="align-self-center ml-4">
              <div class="fs-10 mb-1">選択中</div>
              <v-chip small label class="px-4">
                {{ selected.length }}
              </v-chip>
            </v-card>

            <v-card flat class="align-self-center ml-4">
              <div class="fs-10 mb-1">請求額</div>
              <v-chip small label class="px-4">
                {{ selectedValues.invoiceAmount | currency }}
              </v-chip>
            </v-card>

            <v-card flat class="align-self-center ml-4">
              <div class="fs-10 mb-1">源泉税</div>
              <v-chip small label class="px-4">
                {{ selectedValues.gensenAmount | currency }}
              </v-chip>
            </v-card>

            <v-card flat class="align-self-center ml-4">
              <div class="fs-10 mb-1">支払額</div>
              <v-chip
                v-tooltip="
                  'マイナス値で登録されている場合は、減算されています。<br>（実際の振込額とは異なる場合があります）'
                "
                small
                label
                class="px-4"
              >
                {{ selectedValues.paymentAmount | currency }}
              </v-chip>
            </v-card>

            <v-card flat class="align-self-center ml-4">
              <div class="fs-10 mb-1">振込データ表示金額</div>
              <v-chip
                v-tooltip="
                  '振込データ（FB、総合振込）を出力した時に表示される合計金額です。<br>（支払額がマイナスの場合、振込以外の支払方法の場合は除外されます）'
                "
                small
                label
                class="px-4"
              >
                {{ selectedValues.exportPayAmount | currency }}
              </v-chip>
            </v-card>
          </template>
        </v-card>
      </v-col>

      <v-col cols="4" class="text-right">
        <v-pagination
          v-model="page"
          circle
          :length="lastPage"
          style="width: auto"
          @input="onPagenation"
        />
      </v-col>
    </v-toolbar>
  </div>
</template>

<script>
import Snackbar from 'Helpers/snackbar/index'
import HeaderCell from 'Components/Table/HeaderCell'
import SelectAllHeaderCell from 'Components/Table/SelectAllHeaderCell'
import DialogEditTransferFee from '@/views/payment/components/Table/Dialog/DialogEditTransferFee'
import { checkInclude } from 'Helpers/helpers'
import { convertPaymentType } from 'Helpers/payment/index'
import { formatDate, convertToDatetime } from 'Helpers/date/index'
import { removeAbbreviationCompanyTypes, convertText } from 'Helpers/table/sort'
import { scrollTableToTop } from 'Helpers/table/scroll'
import { mapGetters } from 'vuex'

export default {
  components: {
    HeaderCell,
    SelectAllHeaderCell,
    DialogEditTransferFee,
  },
  model: {
    prop: 'value',
    event: 'select',
  },
  props: {
    tableId: {
      type: String,
      default: 'sweeep-payment-table',
    },
    items: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Object,
      default: () => {
        return {}
      },
    },
    itemsPerPage: {
      type: Number,
      default: 20,
    },
    height: {
      type: String,
      default: 'calc(100vh - 238px)',
    },
    userBankList: {
      type: Array,
      required: true,
    },
    multiUserBank: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const t = this
    return {
      editingItem: '',
      editingtransferFee: '',
      page: 1,

      sortParams: {
        mode: 'asc',
        value: 'summary_id',
        options: {},
      },

      selectedValues: {
        invoiceAmount: 0,
        gensenAmount: 0,
        paymentAmount: 0,
        exportPayAmount: 0,
      },

      editable: false,

      headers: [
        {
          text: '',
          value: 'selected',
          sortable: false,
          display: true,
        },
        {
          text: t.$t('message.payment_table_payment_local_id'),
          value: 'id',
          sortable: false,
          display: true,
        },
        {
          text: t.$t('message.payment_table_payment_date'),
          value: 'pay_date',
          sortable: false,
          display: true,
        },
        {
          text: t.$t('message.payment_table_business_partner'),
          value: 'company_name',
          sortable: false,
          display: true,
        },
        {
          text: t.$t('message.payment_table_payment_way'),
          value: 'pay_type',
          sortable: false,
          display: true,
        },
        {
          text: t.$t('message.payment_table_bank_name'),
          value: 'bank_code',
          sortable: false,
          display: true,
        },
        {
          text: t.$t('message.payment_table_user_bank'),
          value: 'user_bank',
          sortable: false,
          display: true,
        },
        {
          text: t.$t('message.payment_table_amount'),
          value: 'invoice_amount',
          sortable: false,
          display: true,
        },
        {
          text: t.$t('message.payment_table_gensen_amount'),
          value: 'gensen_amount',
          sortable: false,
          display: true,
        },
        {
          text: t.$t('message.payment_table_pay_amount'),
          value: 'pay_amount',
          sortable: false,
          display: true,
        },
        {
          text: t.$t('message.payment_table_transfer_fee'),
          value: 'transfer_fee',
          sortable: false,
          display: true,
        },
        {
          text: t.$t('message.payment_table_pay_amount_deduct_transfer_fee'),
          value: 'pay_amount_deduct_transfer_fee',
          sortable: false,
          display: true,
        },
        {
          text: t.$t('message.payment_table_payment_status'),
          value: 'paid',
          sortable: false,
          display: true,
        },
        {
          text: t.$t('message.payment_table_export_count'),
          value: 'pay_export_count',
          sortable: false,
          display: true,
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['settingPaidByCustomer']),
    selected: function () {
      let items = this.filteredItems.filter((obj) => obj.selected)
      this.$emit('select', items)
      return items
    },
    numSelected: function () {
      try {
        return this.selected.length
      } catch (e) {
        return 0
      }
    },
    startIndex: function () {
      return (this.page - 1) * this.itemsPerPage
    },
    endIndex: function () {
      const start = this.startIndex
      const itemsPerPage = this.itemsPerPage
      return this.filteredItems.length > start + itemsPerPage
        ? start + itemsPerPage
        : this.filteredItems.length
    },
    lastPage: function () {
      return Math.floor(this.filteredItems.length / this.itemsPerPage) + 1
    },
    filteredItems: function () {
      return this.filterItems(this.items, this.filters, '', [])
    },
    sortedItems: function () {
      const value = this.sortParams.value
      const mode = this.sortParams.mode
      const options = this.sortParams.options
      return this.sortItems(this.filteredItems, value, mode, options)
    },
    computedItems: function () {
      return this.sortedItems.slice(this.startIndex, this.endIndex)
    },
    computedHeaders: function () {
      let deleteHeaderValueList = []

      if (this.userBankList.length <= 1 && !this.multiUserBank) {
        deleteHeaderValueList.push('user_bank')
      }
      if (!this.settingPaidByCustomer) {
        deleteHeaderValueList.push(
          'transfer_fee',
          'pay_amount_deduct_transfer_fee'
        )
      }

      if (deleteHeaderValueList.length > 0) {
        return this.headers.filter(
          (item) => !deleteHeaderValueList.includes(item.value)
        )
      } else {
        return this.headers
      }
    },
  },
  watch: {
    numSelected: function () {
      this.computeSelectedValues()
    },
    filteredItems: {
      handler() {
        this.checkSelected()
      },
      deep: true,
    },
  },
  mounted() {
    this.checkEditable()
  },
  methods: {
    onClickSort({ value, mode }) {
      // change sort params
      switch (mode) {
        case 'asc':
          this.sortParams.value = value
          this.sortParams.mode = 'asc'
          break
        case 'desc':
          this.sortParams.value = value
          this.sortParams.mode = 'desc'
          break
        case 'clear':
          this.sortParams.value = 'summary_id'
          this.sortParams.mode = 'asc'
          break
        default:
          this.sortParams.value = 'summary_id'
          this.sortParams.mode = 'asc'
      }
    },
    onClickSelectAll(selectType, message) {
      try {
        //selectType  1:全選択 2:現在ページ選択 3:全解除 4:現在ページ解除
        switch (selectType) {
          case 1: {
            for (let item of this.filteredItems) {
              this.$set(item, 'selected', true)
            }
            break
          }
          case 2: {
            for (let item of this.computedItems) {
              this.$set(item, 'selected', true)
            }
            break
          }
          case 3: {
            for (let item of this.filteredItems) {
              this.$set(item, 'selected', false)
            }
            break
          }
          case 4: {
            for (let item of this.computedItems) {
              this.$set(item, 'selected', false)
            }
            break
          }
        }
        Snackbar.success(message)
      } catch (e) {
        console.log(e)
      }
    },
    onClickSelectOne(item, value) {
      this.$set(item, 'selected', value)
    },
    checkSelected() {
      // filter範囲外のselectedをfalseにする
      try {
        const payKeys = this.selected.map((obj) => obj.pay_key)
        for (let item of this.items) {
          if (item.selected && !payKeys.includes(item.pay_key)) {
            item.selected = false
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    setPage(number) {
      this.page = number
    },
    computeSelectedValues() {
      try {
        let total_invoice_amount = 0
        let total_gensen_amount = 0
        let total_payment_amount = 0
        let total_export_payment_amount = 0
        for (let item of this.selected) {
          total_invoice_amount += parseFloat(item.invoice_amount)
          total_gensen_amount += parseFloat(item.gensen_amount)
          total_payment_amount += parseFloat(item.pay_amount)
          //支払額が0より大きい支払タイプが振込
          if (item.pay_amount > 0 && item.pay_type === 1) {
            total_export_payment_amount += parseFloat(item.pay_amount)
          }
        }
        this.selectedValues.invoiceAmount = total_invoice_amount
        this.selectedValues.gensenAmount = total_gensen_amount
        this.selectedValues.paymentAmount = total_payment_amount
        this.selectedValues.exportPayAmount = total_export_payment_amount
      } catch (e) {
        console.log(e)
      }
    },
    showName(name) {
      try {
        if (name) {
          return name
        } else {
          return ''
        }
      } catch (e) {
        return ''
      }
    },
    formatDate(date) {
      return formatDate(date)
    },
    convertPaymentType(type) {
      return convertPaymentType(type)
    },
    onPagenation() {
      scrollTableToTop(this.tableId)
    },
    sortItems(items, value, mode, options = {}) {
      const compare = (after, before) => {
        // null => blank
        after = after == null ? '' : convertText(after)
        before = before == null ? '' : convertText(before)

        // date object => datetime
        const isDateValue = value.includes('date')
        if (isDateValue && typeof after === 'object') {
          after = convertToDatetime(after)
        }
        if (isDateValue && typeof before === 'object') {
          before = convertToDatetime(before)
        }
        if (value === 'bank_account_name' && options.replaceCompanyType) {
          after = removeAbbreviationCompanyTypes(after)
          before = removeAbbreviationCompanyTypes(before)
        }
        if (value === 'user_bank') {
          after = this.convertBankObjectToText(after)
          before = this.convertBankObjectToText(before)
        }

        if (before === after) {
          return 0
        }
        if (mode === 'asc' && (before < after || before === '')) {
          return 1
        }
        if (mode === 'desc' && (before > after || after === '')) {
          return 1
        }
        return -1
      }
      if (value && ['asc', 'desc'].includes(mode)) {
        return items.slice().sort((after, before) => {
          return compare(after[value], before[value])
        })
      } else {
        return items
      }
    },
    convertBankObjectToText(obj) {
      let text = ''
      if (obj.id) {
        text =
          obj.bank_name +
          obj.bank_branch_name +
          obj.bank_account_type +
          obj.bank_account_number
      }
      return text
    },
    onSortBank({ value, mode }) {
      const [_value, boolean] = value.split('.')
      this.sortParams.options = {
        replaceCompanyType: boolean,
      }
      this.onClickSort({ value: _value, mode })
    },
    filterItems(items, filters, filter, headers) {
      try {
        const cf = new this.$MultiFilters(items, filters, filter, headers)
        cf.registerFilter('search_bar', function (search_bar, items) {
          if (!search_bar || search_bar.trim() === '') {
            return items
          }
          var search_txt = search_bar.trim().toLowerCase()
          var items_fil = items.filter((item) => {
            if (checkInclude(item.pay_date, search_txt)) {
              return item
            }
            if (checkInclude(item.company_name, search_txt)) {
              return item
            }
            if (checkInclude(item.bank_name, search_txt)) {
              return item
            }
            if (checkInclude(item.bank_code, search_txt)) {
              return item
            }
            if (checkInclude(item.bank_branch_name, search_txt)) {
              return item
            }
            if (checkInclude(item.bank_branch_code, search_txt)) {
              return item
            }
            if (checkInclude(item.bank_account_number, search_txt)) {
              return item
            }
            if (checkInclude(item.bank_account_type, search_txt)) {
              return item
            }
            if (checkInclude(item.bank_account_name, search_txt)) {
              return item
            }
            if (checkInclude(item.id, search_txt)) {
              return item
            }
          }, search_bar)

          return items_fil
        })

        cf.registerFilter('company_name', function (text, items) {
          if (!text || text.trim() === '') {
            return items
          }
          var items_fil = items.filter((item) => {
            if (!item.company_name || item.company_name.trim() === '') {
              return false
            } else if (
              item.company_name
                .toLowerCase()
                .includes(text.toLowerCase().trim())
            ) {
              return true
            }
          })
          return items_fil
        })

        cf.registerFilter('bank_code', function (text, items) {
          if (!text || text.trim() === '') {
            return items
          }
          var items_fil = items.filter((item) => {
            if (!item.bank_code || item.bank_code.trim() === '') {
              return false
            } else if (item.bank_code.trim() === text.trim()) {
              return true
            }
          })
          return items_fil
        })

        cf.registerFilter('bank_branch_code', function (text, items) {
          if (!text || text.trim() === '') {
            return items
          }
          var items_fil = items.filter((item) => {
            if (!item.bank_branch_code || item.bank_branch_code.trim() === '') {
              return false
            } else if (item.bank_branch_code.trim() === text.trim()) {
              return true
            }
          })

          return items_fil
        })

        cf.registerFilter('bank_account_type', function (text, items) {
          if (!text || text.trim() === '') {
            return items
          }
          var items_fil = items.filter((item) => {
            if (
              !item.bank_account_type ||
              item.bank_account_type.trim() === ''
            ) {
              return false
            } else if (item.bank_account_type.trim() === text.trim()) {
              return true
            }
          })

          return items_fil
        })

        cf.registerFilter('bank_account_number', function (text, items) {
          if (!text || text.trim() === '') {
            return items
          }
          var items_fil = items.filter((item) => {
            if (
              !item.bank_account_number ||
              item.bank_account_number.trim() === ''
            ) {
              return false
            } else if (
              item.bank_account_number
                .toLowerCase()
                .includes(text.toLowerCase().trim())
            ) {
              return true
            }
          })

          return items_fil
        })

        cf.registerFilter('bank_account_name', function (text, items) {
          if (!text || text.trim() === '') {
            return items
          }
          var items_fil = items.filter((item) => {
            if (
              !item.bank_account_name ||
              item.bank_account_name.trim() === ''
            ) {
              return false
            } else if (
              item.bank_account_name
                .toLowerCase()
                .includes(text.toLowerCase().trim())
            ) {
              return true
            }
          })

          return items_fil
        })

        cf.registerFilter('pay_type', function (pay_type, items) {
          var items_fil = items.filter((item) => {
            let pay_type_val = parseInt(item.pay_type)

            if (pay_type.type1 === 1 && pay_type_val === 1) {
              return true
            }
            if (pay_type.type2 === 1 && pay_type_val === 2) {
              return true
            }
            if (pay_type.type3 === 1 && pay_type_val === 3) {
              return true
            }
            if (pay_type.type4 === 1 && pay_type_val === 4) {
              return true
            }
            if (pay_type.type5 === 1 && pay_type_val === 5) {
              return true
            }
            if (pay_type.type6 === 1 && pay_type_val === 6) {
              return true
            }

            if (pay_type.type0 === 1 && pay_type_val === 0) {
              return true
            }
          })

          return items_fil
        })

        cf.registerFilter('user_bank', function (user_bank, items) {
          if (!user_bank) {
            return items
          }
          const items_fil = items.filter((item) => {
            const user_bank_val = parseInt(item.user_bank.id)
            return user_bank_val == user_bank
          })

          return items_fil
        })

        cf.registerFilter('gensenzei', function (gensenzei, items) {
          if (!gensenzei && gensenzei !== 0) {
            return items
          }

          var items_fil = items.filter((item) => {
            let gensenzei_val = parseInt(item.gensenzei)
            return gensenzei_val == gensenzei
          })

          return items_fil
        })

        cf.registerFilter('invoice_amount_1', function (amount, items) {
          if (!amount) {
            return items
          }

          var items_fil = items.filter((item) => {
            var amount_val = parseFloat(item.invoice_amount)
            return amount_val >= amount
          })

          return items_fil
        })

        cf.registerFilter('invoice_amount_2', function (amount, items) {
          if (!amount) {
            return items
          }

          var items_fil = items.filter((item) => {
            var amount_val = parseFloat(item.invoice_amount)
            return amount_val <= amount
          })

          return items_fil
        })

        cf.registerFilter('pay_amount_1', function (amount, items) {
          if (!amount) {
            return items
          }

          var items_fil = items.filter((item) => {
            let amount_val = parseFloat(item.pay_amount)
            return amount_val >= amount
          })

          return items_fil
        })

        cf.registerFilter('pay_amount_2', function (amount, items) {
          if (!amount) {
            return items
          }

          var items_fil = items.filter((item) => {
            let amount_val = parseFloat(item.pay_amount)
            return amount_val <= amount
          })

          return items_fil
        })

        cf.registerFilter('gensen_amount_1', function (amount, items) {
          if (!amount) {
            return items
          }

          var items_fil = items.filter((item) => {
            let amount_val = parseFloat(item.gensen_amount)
            return amount_val >= amount
          })

          return items_fil
        })

        cf.registerFilter('gensen_amount_2', function (amount, items) {
          if (!amount) {
            return items
          }

          var items_fil = items.filter((item) => {
            let amount_val = parseFloat(item.gensen_amount)
            return amount_val <= amount
          })

          return items_fil
        })

        cf.registerFilter('idFrom', function (number, items) {
          if (!number) {
            return items
          }
          var items_fil = items.filter((item) => {
            let paymentID = Number(item.id.slice(1))
            return Number(number) <= paymentID
          })

          return items_fil
        })

        cf.registerFilter('idTo', function (number, items) {
          if (!number) {
            return items
          }
          var items_fil = items.filter((item) => {
            let paymentID = Number(item.id.slice(1))
            return paymentID <= Number(number)
          })

          return items_fil
        })

        cf.registerFilter('paid', function (flag, items) {
          if (!flag && flag !== 0) {
            return items
          }
          var items_fil = items.filter((item) => {
            if (flag == 1) {
              if (item.paid_user) {
                return true
              }
            } else if (flag == 0) {
              if (!item.paid_user) {
                return true
              }
            } else {
              return true
            }
          })

          return items_fil
        })

        cf.registerFilter('exported', function (flag, items) {
          if (!flag && flag !== 0) {
            return items
          }
          var items_fil = items.filter((item) => {
            if (flag == 1) {
              if (item.export_user) {
                return true
              }
            } else if (flag == 0) {
              if (!item.export_user) {
                return true
              }
            } else {
              return true
            }
          })

          return items_fil
        })

        cf.registerFilter('paid_user', function (text, items) {
          if (!text || text.trim() === '') {
            return items
          }
          var items_fil = items.filter((item) => {
            if (!item.paid_user || item.paid_user.trim() === '') {
              return false
            } else if (item.paid_user.trim() === text.trim()) {
              return true
            }
          })

          return items_fil
        })

        cf.registerFilter('export_user', function (text, items) {
          if (!text || text.trim() === '') {
            return items
          }
          var items_fil = items.filter((item) => {
            if (!item.export_user || item.export_user.trim() === '') {
              return false
            } else if (item.export_user.trim() === text.trim()) {
              return true
            }
          })

          return items_fil
        })

        return cf.runFilters()
      } catch (e) {
        console.log(e)
      }
    },
    openEditTransferFee(item) {
      try {
        this.editingItem = item
        this.editingtransferFee = String(item.transfer_fee)
        this.$refs.dialogEditTransferFee.open()
      } catch (e) {
        console.log(e)
      }
    },
    closeEditTransferFee() {
      this.$refs.dialogEditTransferFee.close()
    },
    checkEditable() {
      this.editable = this.checkPrivilege('payment:update')
    },
  },
}
</script>
