import { quoteCommaAndNewline } from 'Helpers/helpers'
import Encoding from 'encoding-japanese'
import { formatDate } from 'Helpers/date/index'
import { convertPaymentTypeToText } from 'Helpers/payment/index'
import { computeDescription } from 'Helpers/invoice/index'

const comma = ','
const br = '\n'

function getAttr(item, attrs) {
  try {
    let value = Object.assign({}, item)
    let keys = attrs.split('.')
    let key = ''

    for (let i = 0; i < keys.length; i++) {
      key = keys[i]

      try {
        if (key.indexOf('journal') !== -1) {
          const journalIndex = Number(key.match(/\d{1,}/))
          value = value.journal[journalIndex]
        } else {
          value = value[key]
        }
      } catch (e) {
        return ''
      }
      if (i === keys.length - 1) {
        return value
      }
    }
  } catch (e) {
    console.log(e)
    return null
  }
}

export function downloadInvoices(items, charset, options) {
  const filename = 'Invoice.csv'

  const columns = [
    { name: '伝票No.', value: 'image_local_id' },

    {
      name: '請求書ファイル名',
      value: 'image__upload_source',
      format: (string) => string.normalize(),
    },

    { name: '読取日付', value: 'reading_date' },
    { name: '伝票日付', value: 'issue_date' },
    { name: '支払日付', value: 'pay_date' },

    { name: '取引先名', value: 'company_name' },
    { name: 'インボイス番号', value: 'invoice_issuer_number', format: (v) => (v ? 'T' + String(v) : '')},
    { name: '適格請求', value: 'is_invoice_issuer', format: (v) => (v ? '適格請求' : '')},

    { name: '請求額', value: 'debit_amount_total' },
    { name: '税額', value: 'debit_tax_amount_total' },
    { name: '支払額', value: 'pay_amount' },
    { name: '源泉税額', value: 'gensen_amount' },

    { name: '借方勘定科目', value: 'journal[0].debit_account_title.name' },
    { name: '借方補助科目', value: 'journal[0].debit_sub_account.name' },
    { name: '借方部門', value: 'journal[0].debit_department.name' },
    { name: '借方取引先', value: 'journal[0].debit_vendor.name' },
    { name: '借方プロジェクト', value: 'journal[0].debit_project.name' },
    { name: '借方セグメント', value: 'journal[0].debit_segment.name' },
    { name: '借方税区分', value: 'journal[0].debit_tax_class.name' },
    { name: '借方品目', value: 'journal[0].debit_item.name' },
    { name: '借方タグ', value: 'journal[0].debit_tag.name' },
    { name: '借方口座', value: 'journal[0].debit_walletable.name' },

    { name: '貸方勘定科目', value: 'journal[0].credit_account_title.name' },
    { name: '貸方補助科目', value: 'journal[0].credit_sub_account.name' },
    { name: '貸方部門', value: 'journal[0].credit_department.name' },
    { name: '貸方取引先', value: 'journal[0].credit_vendor.name' },
    { name: '貸方プロジェクト', value: 'journal[0].credit_project.name' },
    { name: '貸方セグメント', value: 'journal[0].credit_segment.name' },
    { name: '貸方税区分', value: 'journal[0].credit_tax_class.name' },
    { name: '貸方品目', value: 'journal[0].credit_item.name' },
    { name: '貸方タグ', value: 'journal[0].credit_tag.name' },
    { name: '貸方口座', value: 'journal[0].credit_walletable.name' },

    { name: '摘要', value: 'journal[0].description' },
    { name: '備考1', value: 'journal[0].free_text_1' },
    { name: '備考2', value: 'journal[0].free_text_2' },

    { name: '支払先 銀行コード', value: 'bank_data.bank_code' },
    { name: '支払先 銀行名', value: 'bank_data.bank_name' },
    { name: '支払先 支店コード', value: 'bank_data.branch_code' },
    { name: '支払先 支店名', value: 'bank_data.branch_name' },
    { name: '支払先 口座種別', value: 'bank_data.bank_account_type' },
    { name: '支払先 口座番号', value: 'bank_data.bank_account_number' },
    { name: '支払先 口座名義', value: 'bank_data.bank_account_name' },

    { name: '読取 経路', value: 'upload_type' },
    { name: '読取 日時', value: 'date_upload' },
    { name: '読取 ユーザ名', value: 'upload_user.full_name' },
    { name: '読取 部門名', value: 'upload_user.department_name' },
    { name: '読取 部門コード', value: 'upload_user.department_code' },

    { name: '仕訳 更新 日時', value: 'date_update' },
    { name: '仕訳 更新 ユーザ名', value: 'update_user.full_name' },
    { name: '仕訳 更新 部門名', value: 'update_user.department_name' },
    { name: '仕訳 更新 部門コード', value: 'update_user.department_code' },

    { name: '仕訳 承認1 日時', value: 'date_approve1' },
    { name: '仕訳 承認1 ユーザ名', value: 'approve_user1.full_name' },
    { name: '仕訳 承認1 部門名', value: 'approve_user1.department_name' },
    { name: '仕訳 承認1 部門コード', value: 'approve_user1.department_code' },

    { name: '仕訳 承認2 日時', value: 'date_approve2' },
    { name: '仕訳 承認2 ユーザ名', value: 'approve_user2.full_name' },
    { name: '仕訳 承認2 部門名', value: 'approve_user2.department_name' },
    { name: '仕訳 承認2 部門コード', value: 'approve_user2.department_code' },

    { name: '仕訳 承認3 日時', value: 'date_approve3' },
    { name: '仕訳 承認3 ユーザ名', value: 'approve_user3.full_name' },
    { name: '仕訳 承認3 部門名', value: 'approve_user3.department_name' },
    { name: '仕訳 承認3 部門コード', value: 'approve_user3.department_code' },

    { name: '仕訳 承認4 日時', value: 'date_approve4' },
    { name: '仕訳 承認4 ユーザ名', value: 'approve_user4.full_name' },
    { name: '仕訳 承認4 部門名', value: 'approve_user4.department_name' },
    { name: '仕訳 承認4 部門コード', value: 'approve_user4.department_code' },

    { name: '仕訳 承認5 日時', value: 'date_approve5' },
    { name: '仕訳 承認5 ユーザ名', value: 'approve_user5.full_name' },
    { name: '仕訳 承認5 部門名', value: 'approve_user5.department_name' },
    { name: '仕訳 承認5 部門コード', value: 'approve_user5.department_code' },

    { name: '仕訳 出力 日時', value: 'date_export' },
    { name: '仕訳 出力 ユーザ名', value: 'export_user.full_name' },
    { name: '仕訳 出力 部門名', value: 'export_user.department_name' },
    { name: '仕訳 出力 部門コード', value: 'export_user.department_code' },

    { name: '支払 更新 日時', value: 'date_update_bank' },
    { name: '支払 更新 ユーザ名', value: 'user_update_bank.full_name' },
    { name: '支払 更新 部門名', value: 'user_update_bank.department_name' },
    { name: '支払 更新 部門コード', value: 'user_update_bank.department_code' },

    { name: '支払 確認1 日時', value: 'date_pay_confirm' },
    { name: '支払 確認1 ユーザ名', value: 'pay_confirm_user.full_name' },
    { name: '支払 確認1 部門名', value: 'pay_confirm_user.department_name' },
    {
      name: '支払 確認1 部門コード',
      value: 'pay_confirm_user.department_code',
    },

    { name: '支払 確認2 日時', value: 'date_pay_confirm2' },
    { name: '支払 確認2 ユーザ名', value: 'pay_confirm_user2.full_name' },
    { name: '支払 確認2 部門名', value: 'pay_confirm_user2.department_name' },
    {
      name: '支払 確認2 部門コード',
      value: 'pay_confirm_user2.department_code',
    },

    { name: '支払 確認3 日時', value: 'date_pay_confirm3' },
    { name: '支払 確認3 ユーザ名', value: 'pay_confirm_user3.full_name' },
    { name: '支払 確認3 部門名', value: 'pay_confirm_user3.department_name' },
    {
      name: '支払 確認3 部門コード',
      value: 'pay_confirm_user3.department_code',
    },

    { name: '支払 連携 日時', value: 'date_pay_approve' },
    { name: '支払 連携 ユーザ名', value: 'pay_approve_user.full_name' },
    { name: '支払 連携 部門名', value: 'pay_approve_user.department_name' },
    {
      name: '支払 連携 部門コード',
      value: 'pay_approve_user.department_code',
    },

    { name: '支払 日時', value: 'date_paid' },
    { name: '支払 ユーザ名', value: 'paid_user.full_name' },
    { name: '支払 部門名', value: 'paid_user.department_name' },
    { name: '支払 部門コード', value: 'paid_user.department_code' },

    { name: '支払 出力 日時', value: 'date_pay_export' },
    { name: '支払 出力 ユーザ名', value: 'pay_export_user.full_name' },
    { name: '支払 出力 部門名', value: 'pay_export_user.department_name' },
    { name: '支払 出力 部門コード', value: 'pay_export_user.department_code' },
    { name: '支払方法', value: 'pay_type', format: convertPaymentTypeToText },
    {
      name: '源泉徴収',
      value: 'gensenzei',
      format: (v) => (v ? '源泉対象' : ''),
    },
    { name: '支払メモ1', value: 'payment_note1' },
    { name: '支払メモ2', value: 'payment_note2' },
    { name: '支払メモ3', value: 'payment_note3' },

    { name: '所管部門', value: 'department.name' },

    {
      name: '通貨コード',
      value: 'currency_code',
      hide: !options.settingForeignCurrency,
    },
    {
      name: '外貨金額',
      value: 'foreign_currency_amount',
      hide: !options.settingForeignCurrency,
    },

    { name: '', value: 'timestamp_expiration_date_time' }, // 必ず最後に配置
  ]

  return new Promise((resolve) => {
    setTimeout(() => {
      const filteredColumns = columns.filter((col) => !col.hide)
      downloadCSV(items, filteredColumns, filename, charset).then((result) => {
        resolve(result)
      })
    }, 50)
  })
}

export async function downloadPayments(items, charset) {
  const filename = 'Payment.csv'

  const columns = [
    { name: '支払No.', value: 'id' },
    { name: '支払日', value: 'pay_date' },

    { name: '請求元', value: 'company_name' },
    { name: '支払方法', value: 'pay_type', format: convertPaymentTypeToText },

    { name: '金融機関コード', value: 'bank_code' },
    { name: '金融機関名', value: 'bank_name' },
    { name: '支店コード', value: 'bank_branch_code' },
    { name: '支店名', value: 'bank_branch_name' },
    { name: '口座種別', value: 'bank_account_type' },
    { name: '口座番号', value: 'bank_account_number' },
    { name: '口座名義', value: 'bank_account_name' },
    { name: '振込元金融機関コード', value: 'user_bank.bank_code' },
    { name: '振込元金融機関名', value: 'user_bank.bank_name' },
    { name: '振込元支店コード', value: 'user_bank.bank_branch_code' },
    { name: '振込元支店名', value: 'user_bank.bank_branch_name' },
    { name: '振込元口座種別', value: 'user_bank.bank_account_type' },
    { name: '振込元口座番号', value: 'user_bank.bank_account_number' },

    { name: '請求額', value: 'invoice_amount' },
    { name: '源泉税額', value: 'gensen_amount' },
    { name: '支払額', value: 'pay_amount' },
    { name: '先方負担振込手数料', value: 'transfer_fee' },
    { name: '振込手数料控除後支払額', value: 'pay_amount_deduct_transfer_fee' },

    { name: '支払 日時', value: 'date_paid', format: formatDate },
    { name: '支払 ユーザ名', value: 'paid_user' },

    { name: '仕訳出力 日時', value: 'date_export', format: formatDate },
    { name: '仕訳出力 ユーザ名', value: 'export_user' },
    { name: '仕訳出力 回数', value: 'export_count' },
  ]

  return new Promise((resolve) => {
    setTimeout(async () => {
      const result = await downloadCSV(items, columns, filename, charset)
      resolve(result)
    }, 50)
  })
}

function getAddtionalRow(item, columns) {
  const journalLength = item.journal.length
  const rows = ['']
  let row, attrs, attrsReplacedIndex

  if (journalLength === 1) {
    return ''
  }

  for (let i = 1; i < journalLength; i++) {
    row = columns
      .map((col) => {
        attrs = col.value
        if (attrs.indexOf('journal[0]') !== -1) {
          attrsReplacedIndex = attrs.replace('0', String(i))
          return getColValue(item, col, attrsReplacedIndex)
        } else {
          return ''
        }
      })
      .join(comma)
    rows.push(row)
  }
  return rows.join(br)
}

function getColValue(item, col, attrs) {
  let value = getAttr(item, attrs)

  if (col.hasOwnProperty('format')) {
    value = col.format(value)
  }
  if (attrs.match('journal.+description')) {
    const issue_date = getAttr(item, 'issue_date')
    value = computeDescription(value, issue_date)
  }

  if (value === undefined || value === null) {
    value = ''
  }
  if (typeof value === 'string') {
    value = quoteCommaAndNewline(value)
  }
  return value
}

export function downloadCSV(items, columns, filename, charset) {
  return new Promise((resolve) => {
    try {
      let csvString = ''
      let value = null
      let rowValues = []

      // header
      csvString =
        columns
          .map((col) => {
            return col.name
          })
          .join(comma) + br

      csvString += items
        .map((item) => {
          rowValues = columns
            .map((col) => {
              value = getColValue(item, col, col.value)
              return value
            })
            .join(comma)

          const addtionalRow = item.journal
            ? getAddtionalRow(item, columns)
            : []
          return rowValues + addtionalRow
        })
        .join(br)

      let a = document.createElement('a')
      if (charset === 'utf-8') {
        let blob = new Blob([csvString], { type: 'text/csv;charset=utf-8' })
        a.href = window.URL.createObjectURL(blob)
      } else {
        const uniArray = Encoding.stringToCode(csvString)
        const sjisArray = Encoding.convert(uniArray, {
          to: 'SJIS',
          from: 'UNICODE',
        })
        const unit8Array = new Uint8Array(sjisArray)
        let blob = new Blob([unit8Array], {
          type: 'text/csv;charset=Shift_JIS',
        })
        a.href = window.URL.createObjectURL(blob)
      }
      a.download = filename
      a.click()
      resolve(true)
    } catch (err) {
      resolve(false)
    }
  })
}
