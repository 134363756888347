<template>
  <div id="invoice-wrapper">
    <template>
      <div
        v-if="loading"
        class="app-flex justify-center align-center h-vh-100"
        style="height: 500px"
      >
        <v-progress-circular indeterminate color="primary" />
      </div>
      <div
        v-else-if="pdfUrl"
        :class="$style.paymentInvoiceImagePdfView"
        style="height: 600px; border-bottom: 4px solid #515659"
      >
        <v-fab-transition>
          <v-btn
            v-tooltip="{ content: 'ダウンロード', placement: 'left' }"
            small
            color="grey lighten-3"
            absolute
            fab
            style="top: 70px; right: 20px"
            @click="$emit('download-file')"
          >
            <v-icon x-small> feather-download </v-icon>
          </v-btn>
        </v-fab-transition>
        <embed
          id="invoicepdf"
          type="application/pdf"
          :src="pdfUrl + '#view=Fit'"
          width="100%"
          height="100%"
          @load="$emit('load')"
        />
      </div>
    </template>
  </div>
</template>

<style module>
.paymentInvoiceImagePdfView {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
</style>

<script>
export default {
  props: {
    pdfUrl: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
