<template>
  <div class="w-100">
    <payment-status
      ref="dialogStatus"
      :status-data="statusData"
      :selected-item="selectedStatus"
      @onClick="filterBySelectedStatus()"
    />

    <v-toolbar flat color="white" height="50">
      <v-btn
        v-tooltip="
          filter_form_entered || filters.search_bar
            ? 'フィルタが指定されています'
            : ''
        "
        icon
        @click="onClick()"
      >
        <v-badge
          v-if="filter_form_entered"
          overlap
          dot
          offset-x="6"
          offset-y="8"
          color="red"
        >
          <span slot="badge" />
          <s-icon
            class="tw-pa-3"
            color="gray-700"
            icon="feather-filter"
            size="xl"
          />
        </v-badge>
        <s-icon
          v-else
          class="tw-pa-3"
          color="gray-700"
          icon="feather-filter"
          size="xl"
        />
      </v-btn>
      <span class="ml-5 fs-10 mr-2">
        {{ $t('message.paymentDate') }}
      </span>
      <v-btn
        v-tooltip="'切替え'"
        small
        outlined
        class="grey lighten-3 mx-2"
        @click="onChangeDateSearchMode()"
      >
        <s-icon icon="feather-calendar" />
      </v-btn>
      <template v-if="dateSearchMode">
        <date-pick-input v-model="filters.date" @change="getTableData" />
        <span class="mx-2">〜</span>
        <date-pick-input v-model="filters.to_date" @change="getTableData" />
        <v-btn-toggle dense active-class="active-btn-opacity" class="ml-2">
          <v-btn small @click="filterBtnPrevMonth()">
            <s-icon icon="feather-chevron-left" />
          </v-btn>
          <v-btn small @click="filterBtnTargetMonth()"> 今月 </v-btn>
          <v-btn small @click="filterBtnNextMonth()">
            <s-icon icon="feather-chevron-right" />
          </v-btn>
        </v-btn-toggle>
      </template>
      <template v-else>
        <month-picker-toggle-button
          :date-from="filters.date"
          :date-to="filters.to_date"
          @select="setDateFromMonthPicker($event)"
        />
      </template>

      <v-spacer />

      <v-text-field
        v-model="filters.search_bar"
        v-tooltip="
          '入力した文字とマッチ（部分一致）する請求書を抽出します。<br>請求書に紐づく全ての項目に対してフィルタを適用します。'
        "
        append-icon="search"
        placeholder="簡単フィルタ"
        dense
        filled
        hide-details
        clearable
        class="more-dense mx-2"
        style="max-width: 400px"
      />

      <v-btn
        small
        depressed
        color="grey lighten-3"
        class="fs-12"
        @click="openStatusDialog()"
      >
        <i class="ti-alert mr-2" />
        チェック
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
import PaymentStatus from './PaymentStatus'
import DatePickInput from 'Components/Input/DatePickInput'
import MonthPickerToggleButton from 'Components/Button/MonthPickerToggleButton'

export default {
  components: {
    PaymentStatus,
    DatePickInput,
    MonthPickerToggleButton,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    statusData: {
      type: Array,
      required: true,
    },
    onClick: {
      type: Function,
      required: true,
    },
    filterBtnTargetMonth: {
      type: Function,
      required: true,
    },
    filterBtnPrevMonth: {
      type: Function,
      required: true,
    },
    filterBtnNextMonth: {
      type: Function,
      required: true,
    },
    getTableData: {
      type: Function,
      required: true,
    },
    getStatusData: {
      type: Function,
      required: true,
    },
    clearAllFilter: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      menu: false,
      menu_todate: false,
      selectedStatus: {
        date: '',
        name: '',
        count: '',
      },
      dateSearchMode: true,
    }
  },
  computed: {
    filter_form_entered: function () {
      try {
        for (let key of Object.keys(this.filters)) {
          let ignore_keys = ['date', 'to_date', 'pay_type']
          if (
            (this.filters[key] || this.filters[key] === 0) &&
            ignore_keys.includes(key) === false
          ) {
            return true
          }
        }
        for (let key of Object.keys(this.filters.pay_type)) {
          if (this.filters.pay_type[key] !== 1) {
            return true
          }
        }
        return false
      } catch (e) {
        return false
      }
    },
  },
  mounted() {
    this.setDateSearchMode()
  },
  methods: {
    inputDate(date) {
      this.$refs.menu.save(date)
    },
    inputToDate(date) {
      this.$refs.menu_to_date.save(date)
    },
    clearDate() {
      this.filters.date = ''
    },
    clearToDate() {
      this.filters.to_date = ''
    },
    toDateString(datetime) {
      var yyyy = datetime.getFullYear()
      var mm = ('0' + (datetime.getMonth() + 1)).slice(-2)
      var dd = ('0' + datetime.getDate()).slice(-2)
      return yyyy + '-' + mm + '-' + dd
    },
    openStatusDialog() {
      this.getStatusData()
      this.$refs.dialogStatus.open()
    },
    filterBySelectedStatus() {
      try {
        this.filters.date = this.selectedStatus.date + '-01'
        var date_split = this.filters.date.split('-')
        this.filters.to_date = this.toDateString(
          new Date(date_split[0], date_split[1], 0)
        )

        if (this.selectedStatus.name === 'notPaid') {
          this.clearAllFilter().then(() => {
            this.$set(this.filters, 'paid', 0)
          })
        }
        if (this.selectedStatus.name === 'paid') {
          this.clearAllFilter().then(() => {
            this.$set(this.filters, 'paid', 1)
          })
        }
        if (this.selectedStatus.name === 'notExported') {
          this.clearAllFilter().then(() => {
            this.$set(this.filters, 'exported', 0)
          })
        }
        if (this.selectedStatus.name === 'exported') {
          this.clearAllFilter().then(() => {
            this.$set(this.filters, 'exported', 1)
          })
        }
        this.getTableData()
      } catch (e) {
        console.log(e)
      }
    },
    setDateFromMonthPicker(event) {
      try {
        this.$set(this.filters, 'date', event.dateFrom)
        this.$set(this.filters, 'to_date', event.dateTo)
        this.getTableData()
      } catch (e) {
        console.log(e)
      }
    },
    setDateSearchMode() {
      const key = 'dateSearchModeOnPaymentHeader'
      if (Object.prototype.hasOwnProperty.call(localStorage, key)) {
        const value = JSON.parse(localStorage.getItem(key))
        this.dateSearchMode = value
      }
    },
    onChangeDateSearchMode() {
      const mode = !this.dateSearchMode
      this.dateSearchMode = mode
      const key = 'dateSearchModeOnPaymentHeader'
      const value = JSON.stringify(mode)
      localStorage.setItem(key, value)
    },
  },
}
</script>
