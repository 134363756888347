<template>
  <div
    v-if="sortable && sortItems.length == 0"
    :style="`min-width:${width};`"
    @click="onClick"
  >
    <span v-tooltip="'クリックで並び替え'" class="pointer">
      {{ title }}
    </span>
    <header-sort-arrow :item="{ value: sortValue }" :sort-params="sortParams" />
  </div>
  <div v-else-if="sortable && sortItems.length > 0">
    <multi-sort-header-cell
      :style="`min-width:${width};`"
      :title="title"
      :sort-params="sortParams"
      :sort-items="sortItems"
      @click="$emit('click', $event)"
    />
  </div>
  <div v-else>
    {{ title }}
  </div>
</template>

<script>
import HeaderSortArrow from 'Components/Table/HeaderSortArrow'
import MultiSortHeaderCell from 'Components/Table/MultiSortHeaderCell'

export default {
  components: {
    HeaderSortArrow,
    MultiSortHeaderCell,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    sortValue: {
      type: String,
      default: '',
    },
    sortable: {
      type: Boolean,
      default: true,
    },
    sortParams: {
      type: Object,
      default: () => {
        return {}
      },
    },
    sortItems: {
      type: Array,
      default: () => {
        return []
      },
    },
    width: {
      type: String,
      default: '70px',
    },
  },
  methods: {
    onClick() {
      try {
        const value = this.sortValue
        let mode
        if (this.sortParams.value === value && this.sortParams.mode === 'asc') {
          mode = 'desc'
        } else if (
          this.sortParams.value === value &&
          this.sortParams.mode === 'desc'
        ) {
          mode = 'clear'
        } else {
          mode = 'asc'
        }
        this.$emit('click', { value, mode })
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>
