<template>
  <v-navigation-drawer
    v-model="drawer"
    fixed
    width="1200"
    right
    temporary
    hide-overlay
    scrollable
    class="clearfix"
  >
    <v-card>
      <v-layout>
        <v-flex xs6 sm6 md5>
          <payment-detail
            :form-payment="formPayment"
            :payment-journal-list="paymentJournalList"
            :invoice-summary-list="invoiceSummaryList"
            :definition="definition"
          />
        </v-flex>
        <v-flex xs6 sm6 md7>
          <payment-invoice-list
            :invoice-list="invoiceSummaryList"
            @load="$emit('load')"
            @download-file="$emit('download-file', $event)"
          />
        </v-flex>
      </v-layout>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import PaymentDetail from '../../Detail/PaymentDetail'
import PaymentInvoiceList from '../../Detail/PaymentInvoiceList'

export default {
  components: {
    PaymentDetail,
    PaymentInvoiceList,
  },
  props: {
    formPayment: {
      type: Object,
      required: true,
    },
    invoiceSummaryList: {
      type: Array,
      required: true,
    },
    paymentJournalList: {
      type: Array,
      required: true,
    },
    definition: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      drawer: false,
    }
  },
  methods: {
    open() {
      this.drawer = true
    },
    close() {
      this.drawer = false
    },
  },
}
</script>
