<template>
  <base-dialog
    ref="dialog"
    :title="$t('message.payment_title_approve_paid')"
    :message="$t('message.payment_message_approve_paid')"
    :cancel-text="$t('message.modal_cancel_btn')"
    :submit-text="$t('message.change')"
    :loading="loading"
    max-width="650"
    @submit="submit()"
  />
</template>

<script>
export default {
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    open() {
      this.stopload()
      this.$refs.dialog.open()
    },
    close() {
      this.stopload()
      this.$refs.dialog.close()
    },
    startload() {
      this.loading = true
    },
    stopload() {
      this.loading = false
    },
    submit() {
      this.startload()
      this.$emit('submit')
    },
  },
}
</script>
