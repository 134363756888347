<template>
  <div>
    <app-section-loader :status="loadingTable" />

    <payment-detail-drawer
      ref="paymentDetailDrawer"
      :form-payment="formPayment"
      :payment-journal-list="paymentJournalList"
      :invoice-summary-list="invoiceSummaryList"
      :definition="definition"
      @load="loadPdf"
      @download-file="downloadFile($event)"
    />

    <bulk-edit-pay-date-dialog
      ref="bulkUpdatePayDateDialog"
      @submit="bulkEditPayDate"
    />

    <approve-paid-dialog ref="approvePaidDialog" @submit="approvePaid" />

    <cancel-paid-dialog ref="cancelPaidDialog" @submit="cancelPaid" />

    <cancel-pay-approve-dialog
      ref="cancelPayApproveDialog"
      @submit="cancelPayApprove"
    />

    <check-bank-holiday-dialog
      ref="checkBankHolidayDialog"
      @submit="$refs.exportZenginDialog.open()"
    />

    <export-zengin-dialog
      ref="exportZenginDialog"
      :num-of-data="numSelected"
      @submit="exportZengin"
    />

    <export-journal-dialog
      ref="exportJournalDialog"
      :num-of-data="numSelected"
      :format-list="exportFormatList"
      @submit="exportPayment"
    />

    <export-table-dialog ref="exportTableDialog" @submit="downloadCSV" />

    <not-exported-items-dialog
      ref="notExportedItemsDialog"
      :items="itemNotExported"
    />

    <bulk-change-user-bank-dialog
      ref="bulkChangeUserBankDialog"
      :user-bank-list="userBankList"
      @submit="bulkChangeUserBank"
    />

    <v-card-text class="pa-0">
      <v-toolbar flat>
        <v-menu nudge-bottom="45" transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-btn
                v-tooltip="'選択中のデータを一括で操作'"
                v-text="'一括操作'"
              />
            </div>
          </template>
          <v-list class="fs-12">
            <v-list-item
              v-if="checkPrivilege('payment:update')"
              class="border-bottom-1"
              @click="$refs.bulkUpdatePayDateDialog.open()"
            >
              <s-icon class="tw-mr-3" icon="ti-calendar" />
              支払日変更
            </v-list-item>
            <v-list-item
              v-if="checkPrivilege('payment:approve5')"
              class="border-bottom-1"
              @click="$refs.approvePaidDialog.open()"
            >
              <s-icon class="tw-mr-3" icon="ti-check" />
              {{ $t('message.payment_paid_status') }}
            </v-list-item>
            <v-list-item
              v-if="checkPrivilege('payment:approve4')"
              class="border-bottom-1"
              @click="$refs.cancelPayApproveDialog.open()"
            >
              <s-icon class="tw-mr-3" icon="ti-unlock" />
              {{ $t('message.payment_cancel_pay_approve') }}
            </v-list-item>

            <v-list-item
              v-if="checkPrivilege('payment:approve5')"
              class="border-bottom-1"
              @click="$refs.cancelPaidDialog.open()"
            >
              <s-icon class="tw-mr-3" icon="ti-unlock" />
              {{ $t('message.payment_cancel_paid') }}
            </v-list-item>

            <v-list-item
              v-if="checkPrivilege('payment:update') && enableToChangeUserBank"
              class="border-bottom-1"
              @click="$refs.bulkChangeUserBankDialog.open()"
            >
              <s-icon class="tw-mr-3" icon="ti-money" />
              {{ $t('message.payment_change_user_bank') }}
            </v-list-item>
          </v-list>
        </v-menu>

        <v-autocomplete
          v-model="filters.paid"
          v-tooltip="'支払ステータスでフィルタをかけます'"
          :items="paidItems"
          label="支払済"
          filled
          outlined
          dense
          hide-details
          clearable
          item-text="name"
          item-value="id"
          class="ml-4"
          style="max-width: 150px"
        />
        <v-autocomplete
          v-model="filters.exported"
          v-tooltip="'仕訳エクスポート出力ステータスでフィルタをかけます'"
          :items="exportItems"
          label="仕訳エクスポート"
          filled
          outlined
          dense
          hide-details
          clearable
          item-text="name"
          item-value="id"
          class="ml-2"
          style="max-width: 150px"
        />
        <v-autocomplete
          v-if="userBankList.length > 1"
          v-model="filters.user_bank"
          v-tooltip="'振込元銀行口座でフィルタをかけます'"
          :items="userBankList"
          label="振込元銀行口座"
          filled
          outlined
          dense
          hide-details
          clearable
          item-text="simpletext"
          item-value="id"
          class="ml-2"
          style="max-width: 250px"
        />

        <v-spacer />

        <base-button
          v-if="checkPrivilege('payment:export_table')"
          icon="ti-export"
          tooltip="表示中の一覧データをCSV形式でダウンロードします"
          :text="$t('message.invoice_list_all_export')"
          @click="$refs.exportTableDialog.open()"
        />

        <base-button
          v-if="checkPrivilege('payment:approve5')"
          icon="ti-wallet"
          :text="$t('message.payment_payment_data_export')"
          @click="checkSelectedBankHoliday()"
        />

        <base-button
          v-if="
            organization.settingPayJournal &&
            checkPrivilege('payment:export_journal')
          "
          icon="ti-export"
          :text="$t('message.payment_journal_data_export')"
          style="margin-right: 0px !important"
          @click="checkDublicateExport"
        />
      </v-toolbar>
    </v-card-text>

    <sweeep-payment-table
      ref="sweeepPaymentTable"
      v-model="selected"
      :items="tableData"
      :filters="filters"
      :items-per-page="20"
      :user-bank-list="userBankList"
      :multi-user-bank="multiUserBank"
      @click:get-detail="getPaymentDetail"
      @editTransferFee="$emit('editTransferFee', $event)"
    />
  </div>
</template>

<script>
import Snackbar from 'Helpers/snackbar/index'
import JapaneseHolidays from 'japanese-holidays'
import axios from 'axios'
import Axios from 'axios'
import Vue from 'vue'
import SweeepPaymentTable from 'Components/Table/SweeepPaymentTable'
import PaymentDetailDrawer from './Drawer/PaymentDetailDrawer'
import BulkEditPayDateDialog from './Dialog/BulkEditPayDateDialog'
import ApprovePaidDialog from './Dialog/ApprovePaidDialog'
import CancelPaidDialog from './Dialog/CancelPaidDialog'
import CancelPayApproveDialog from './Dialog/CancelPayApproveDialog'
import CheckBankHolidayDialog from './Dialog/CheckBankHolidayDialog'
import ExportTableDialog from './Dialog/ExportTableDialog'
import ExportZenginDialog from './Dialog/ExportZenginDialog'
import ExportJournalDialog from './Dialog/ExportJournalDialog'
import NotExportedItemsDialog from './Dialog/NotExportedItemsDialog'
import BulkChangeUserBankDialog from './Dialog/BulkChangeUserBankDialog'
import { createNotification } from 'Helpers/helpers'
import { downloadPayments } from 'Helpers/table/download'
import { getUserBankFullText } from '@/helpers/account/index'

export default {
  components: {
    PaymentDetailDrawer,
    BulkEditPayDateDialog,
    ApprovePaidDialog,
    CancelPaidDialog,
    CancelPayApproveDialog,
    CheckBankHolidayDialog,
    ExportTableDialog,
    ExportZenginDialog,
    ExportJournalDialog,
    NotExportedItemsDialog,
    BulkChangeUserBankDialog,
    SweeepPaymentTable,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    organization: {
      type: Object,
      required: true,
    },
    definition: {
      type: Object,
      required: true,
    },
    tableData: {
      type: Array,
      required: true,
    },
    getTableData: {
      type: Function,
      required: true,
    },
    loadingTable: {
      type: Boolean,
      required: true,
    },
    exportFormatList: {
      type: Array,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    userBankList: {
      type: Array,
      required: true,
    },
    multiUserBank: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      loadPdfCount: 0,

      selected: [],
      invoiceSummaryList: [
        {
          upload_source: '',
          image_loaded: false,
          image_data: [],
        },
      ],
      paymentJournalList: [],
      itemNotExported: [],

      formPayment: {
        id: '',
        local_id: '',
        company_name: '',
        pay_date: '',
        note_1: '',

        invoice_amount: '',
        tax_amount: '',
        reward_amount: '',
        gensen_amount: '',
        pay_amount: '',
        transfer_fee: '',
        pay_amount_deduct_transfer_fee: '',
        user_bank_fulltext: '',

        confirm: false,
        approve: false,

        paymentJournalList: [],
      },
      paidItems: [
        { id: 0, name: '未払' },
        { id: 1, name: '支払済' },
      ],
      exportItems: [
        { id: 0, name: '仕訳未出力' },
        { id: 1, name: '仕訳出力済' },
      ],
    }
  },
  computed: {
    numSelected: function () {
      try {
        return this.selected.length
      } catch (e) {
        return 0
      }
    },
    enableToChangeUserBank: function () {
      return this.userBankList.length > 1 || this.multiUserBank
    },
  },
  mounted() {},
  methods: {
    setPage(number) {
      this.$refs.sweeepPaymentTable.setPage(number)
    },
    initForm() {
      this.invoiceSummaryList = []
      this.formPayment = {
        id: '',
        local_id: '',
        company_name: '',
        pay_date: '',
        note_1: '',

        invoice_amount: '',
        tax_amount: '',
        reward_amount: '',
        gensen_amount: '',
        pay_amount: '',
        transfer_fee: '',
        pay_amount_deduct_transfer_fee: '',
        user_bank_fulltext: '',

        confirm: false,
        approve: false,

        paymentJournalList: [],
      }
    },
    getPdfUrl(index, version = null) {
      try {
        let url = this.$store.getters.apiInvoicePDFUrl
        let org_id = this.$store.getters.getOrganizationID
        let user_org_id = this.$store.getters.getUserOrganizationID

        let invoiceSummary = this.invoiceSummaryList[index]

        invoiceSummary.image_loaded = false
        axios
          .get(url, {
            headers: { Authorization: this.$store.getters.getAuthToken },
            params: {
              local_id: invoiceSummary.local_id,
              organization_id: org_id,
              user_organization_id: user_org_id,
              version: version,
            },
          })
          .then((response) => {
            if (response.data.error) {
              Snackbar.error(response.data.error)
            } else {
              invoiceSummary.pdfUrl = response.data.url
              invoiceSummary.currentVersion = response.data.version
              invoiceSummary.maxVersion = response.data.max_version
              invoiceSummary.image_loaded = true
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    loadPdf() {
      this.loadPdfCount += 1
      if (this.loadPdfCount < this.invoiceSummaryList.length) {
        this.getPdfUrl(this.loadPdfCount)
      }
    },
    downloadFile(event) {
      try {
        const payload = {
          localId: event,
        }
        this.$store.dispatch('downloadInvoicePdfFile', payload)
      } catch (e) {
        console.log(e)
      }
    },
    setCurrency(amount) {
      return this.$options.filters.currency(amount)
    },
    getPaymentDetail(item) {
      try {
        this.loading = true

        let url = this.$store.getters.apiPaymentDetailFunction
        Axios.get(url, {
          headers: { Authorization: this.$store.getters.getAuthToken },
          params: {
            pay_key: item.pay_key,
            organization_id: this.$store.getters.getOrganizationID,
          },
        }).then((response) => {
          if (response.data.error) {
            Snackbar.error(response.data.error)
          } else {
            var paymentSummary = response.data.payment_summary
            var paymentJournalList = response.data.payment_journal_list
            var invoiceSummaryList = response.data.invoice_summary_list

            this.formPayment = paymentSummary
            this.formPayment.invoice_amount = this.setCurrency(
              paymentSummary.invoice_amount
            )
            this.formPayment.tax_amount = this.setCurrency(
              paymentSummary.invoice_tax
            )
            this.formPayment.reward_amount = this.setCurrency(
              paymentSummary.reward_amount
            )
            this.formPayment.gensen_amount = this.setCurrency(
              paymentSummary.gensen_amount
            )
            this.formPayment.pay_amount = this.setCurrency(
              paymentSummary.pay_amount
            )
            this.formPayment.transfer_fee = this.setCurrency(
              paymentSummary.transfer_fee
            )
            this.formPayment.pay_amount_deduct_transfer_fee = this.setCurrency(
              paymentSummary.pay_amount_deduct_transfer_fee
            )
            this.formPayment.user_bank_fulltext = getUserBankFullText(
              paymentSummary.user_bank
            )

            this.formPayment.confirm = paymentSummary.confirm_user
              ? true
              : false
            this.formPayment.approve = paymentSummary.approve_user
              ? true
              : false

            let i, item
            for (i = 0; i < paymentJournalList.length; i++) {
              item = paymentJournalList[i]
              item.debit_amount = this.setCurrency(item.debit_amount)
              item.debit_tax_amount = this.setCurrency(item.debit_tax_amount)
              item.credit_amount = this.setCurrency(item.credit_amount)
              item.credit_tax_amount = this.setCurrency(item.credit_tax_amount)
            }

            this.paymentJournalList = paymentJournalList

            for (i = 0; i < invoiceSummaryList.length; i++) {
              item = invoiceSummaryList[i]
              item.invoice_amount = this.setCurrency(item.invoice_max_amount)
              item.tax_amount = this.setCurrency(item.invoice_tax)
              item.gensen_amount = this.setCurrency(item.gensen_amount)
              item.pay_amount = this.setCurrency(item.pay_amount)
              item.editMode = false
              item.image_loaded = false
            }
            this.invoiceSummaryList = invoiceSummaryList

            Vue.nextTick(() => {
              this.loadPdfCount = 0
              this.getPdfUrl(0)
              this.loading = false
              this.$refs.paymentDetailDrawer.open()
            })
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    getSelectedPayKey() {
      try {
        return this.selected.map((obj) => obj.pay_key)
      } catch (e) {
        console.log(e)
      }
    },
    checkPaymentPrivilege(operation) {
      try {
        return this.selected.every((obj) => obj.privileges[operation])
      } catch (e) {
        console.log(e)
        return false
      }
    },
    getSelectedPayDate() {
      try {
        var pay_date_list = []
        for (let item of this.selected) {
          var pay_date = item.pay_date
          if (pay_date && pay_date_list.includes(pay_date) == false) {
            pay_date_list.push(pay_date)
          }
        }
        return pay_date_list
      } catch (e) {
        console.log(e)
      }
    },
    createExportFileName(formatId) {
      if (!formatId) {
        return
      }
      const format = this.exportFormatList.find((v) => v.id === formatId)
      const formatName = format ? format.name : 'export'
      return formatName + '_' + this.formatExportDate(new Date()) + '.csv'
    },
    formatExportDate(date) {
      // return YYYYMMDD-hhmmss
      return (
        date.getFullYear() +
        ('0' + (date.getMonth() + 1)).slice(-2) +
        ('0' + date.getDate()).slice(-2) +
        '-' +
        ('0' + date.getHours()).slice(-2) +
        ('0' + date.getMinutes()).slice(-2) +
        ('0' + date.getSeconds()).slice(-2)
      )
    },
    getSelectedPayKeySorted() {
      const sortItems = this.$refs.sweeepPaymentTable.sortedItems
      const selectedIds = this.selected.map((obj) => obj.pay_key)
      const payKeysSorted = sortItems
        .map((item) => {
          if (selectedIds.includes(item.pay_key)) {
            return item.pay_key
          }
        })
        .filter((item) => !!item)
      return payKeysSorted
    },
    exportPayment({ formatId, encodeType, withHeader, isQuoteAll }) {
      try {
        if (this.selected.length == 0) {
          this.$refs.exportJournalDialog.stopload()
          Snackbar.error('データを選択してください。')
          return false
        }
        var auth = this.$store.getters.getAuthToken
        var url_check = this.$store.getters.apiPaymentCheckExportFunction
        var url_download = this.$store.getters.apiPaymentExportFunction

        var pay_key_list = JSON.stringify(this.getSelectedPayKeySorted())

        var data = {
          pay_key_list: pay_key_list,
          export_format_id: formatId,
          encode_type: encodeType,
          export_header: withHeader,
          is_quote_all: isQuoteAll,
          user_organization_id: this.$store.getters.getUserOrganizationID,
          organization_id: this.$store.getters.getOrganizationID,
          user_id: this.$store.getters.getUserID,
        }
        $.ajax({
          url: url_check,
          data: data,
          type: 'POST',
          beforeSend: function (xhr) {
            /* Authorization header */
            xhr.setRequestHeader('Authorization', auth)
          },
          success: function (response) {
            if (response.error) {
              Snackbar.error('エクスポートするデータがありません')
              this.$refs.exportJournalDialog.stopload()
            } else {
              var image_summary_id = response.image_summary_id
              $.ajax({
                url: url_download,
                data: data,
                type: 'POST',
                beforeSend: function (xhr) {
                  xhr.setRequestHeader('Authorization', auth)
                },
                xhrFields: {
                  responseType: 'blob',
                },
                success: function (response) {
                  Snackbar.success('データのエクスポートが完了しました。')

                  var a = document.createElement('a')
                  var url = window.URL.createObjectURL(response)
                  a.href = url
                  a.download = this.createExportFileName(formatId)
                  document.body.appendChild(a)
                  a.click()
                  window.URL.revokeObjectURL(url)
                  createNotification(
                    `%${this.$store.getters.getUserOrganizationID}%さんが${this.numSelected}件の支払時仕訳データをエクスポートしました。`,
                    3
                  )
                  const organization_id = this.$store.getters.getOrganizationID
                  axios
                    .put(
                      url_check,
                      { organization_id, image_summary_id },
                      {
                        headers: {
                          Authorization: this.$store.getters.getAuthToken,
                        },
                      }
                    )
                    .then((response) => {
                      let result = response.data.result
                      this.tableData.forEach((v) => {
                        let instance = result.find((val) => {
                          return v.invoice_id_list.indexOf(val.id) != -1
                        })
                        if (instance) {
                          v.pay_key = instance.pay_key
                          v.date_export = instance.date_pay_export
                          v.export_user = instance.pay_export_user
                          v.export_count++
                        }
                      })
                    })
                  this.$refs.exportJournalDialog.close()
                }.bind(this),
              })
            }
          }.bind(this),
        })
      } catch (e) {
        console.log(e)
      }
    },
    async checkSelectedBankHoliday() {
      let pay_date
      let holidays = []
      if (this.selected.length == 0) {
        Snackbar.error('データを選択してください。')
        return false
      }
      if (this.selected.some((item) => item.paid_user)) {
        const yes = await this.$confirm({
          message: '出力済みのデータが含まれます。再度出力しますか？',
        })
        if (!yes) {
          return false
        }
      }

      for (let item of this.selected) {
        if (item.pay_date != '') {
          pay_date = new Date(item.pay_date)
          if (this.isHoliday(pay_date) && holidays.indexOf(item.pay_date) < 0) {
            // 支払日が土日祝日の場合でかつ、休日リストにない場合
            // 休日リストに追加
            holidays.push(item.pay_date)
          }
        }
      }
      if (holidays.length == 0) {
        this.$refs.exportZenginDialog.open()
      } else {
        this.$refs.checkBankHolidayDialog.open({ holidays })
      }
    },
    isHoliday(date) {
      if (JapaneseHolidays.isHoliday(date)) {
        return true
      }
      if (date.getDay() == 0 || date.getDay() == 6) {
        return true
      }
      if (date.getMonth() == 0) {
        if (date.getDate() == 2 || date.getDate() == 3) {
          return true
        }
      }
      if (date.getMonth() == 11) {
        if (date.getDate() == 31) {
          return true
        }
      }
      return false
    },
    exportZengin(fileType) {
      try {
        var auth = this.$store.getters.getAuthToken
        var org_id = this.$store.getters.getOrganizationID
        var user_id = this.$store.getters.getUserID
        var url_check = this.$store.getters.apiPaymentCheckZenginExportFunction

        var pay_date_list = this.getSelectedPayDate()

        if (this.selected.length == 0) {
          Snackbar.error('データを選択してください。')
          this.$refs.exportZenginDialog.stopload()
          return false
        }

        const hasPrivilege = this.checkPaymentPrivilege('approve5')
        if (!hasPrivilege) {
          this.$refs.exportZenginDialog.stopload()
          Snackbar.error('権限のないデータが含まれています')
          return
        }

        if (pay_date_list.length == 0) {
          Snackbar.error('支払日付を入力してください.')
          this.$refs.exportZenginDialog.stopload()
          return false
        }
        if (pay_date_list.length > 1) {
          Snackbar.error(
            '異なる支払日のデータを一括でダウンロードできません。<br>支払日が同じものを選択してダウンロードください。'
          )
          this.$refs.exportZenginDialog.stopload()
          return false
        }
        if (this.checkEnableToExport() === false) {
          Snackbar.error(
            '支払額が0円以下、または支払方法が振込ではない請求書が選択されています。'
          )
          this.$refs.exportZenginDialog.stopload()
          return false
        }
        var pay_key_list = JSON.stringify(this.getSelectedPayKeySorted())
        var pay_date = pay_date_list[0]

        var data = {
          pay_date: pay_date,
          file_type: fileType,
          pay_key_list: pay_key_list,
          user_organization_id: this.$store.getters.getUserOrganizationID,
          organization_id: org_id,
          user_id: user_id,
        }

        axios
          .post(url_check, data, {
            headers: { Authorization: auth },
          })
          .then((response) => {
            if (response.data.error) {
              Snackbar.error(response.data.message)
              this.$refs.exportZenginDialog.stopload()
              return false
            } else {
              if (response.data.export_status == 'success') {
                this.exportZenginOne(data)
              } else if (response.data.export_status == 'is_deleted') {
                this.confirmDeletedUserBank(data)
              }
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    async confirmDeletedUserBank(data) {
      const yes = await this.$confirm({
        message:
          '削除済みの振込元口座の支払データが選択されています。振込データを出力しますか？',
      })
      if (yes) {
        this.exportZenginOne(data)
      } else {
        this.$refs.exportZenginDialog.stopload()
        this.$refs.exportZenginDialog.close()
        return false
      }
    },
    exportZenginOne(data) {
      try {
        var auth = this.$store.getters.getAuthToken
        var url = this.$store.getters.apiPaymentZenginExportFunction
        $.ajax({
          url: url,
          data: data,
          type: 'POST',
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', auth)
          },
          xhrFields: {
            responseType: 'blob',
          },
          success: function (response) {
            Snackbar.success('データのエクスポートが完了しました。')
            createNotification(
              `%${this.$store.getters.getUserOrganizationID}%さんが${this.numSelected}件の振込データを出力しました。`,
              4
            )
            var a = document.createElement('a')
            var url = window.URL.createObjectURL(response)
            a.href = url
            a.download = 'zengin-' + data.pay_date + '.' + data.file_type
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)

            this.getTableData(
              false,
              this.selected.map((v) => v.id)
            ).then(() => {
              this.$refs.exportZenginDialog.close()
              if (this.itemNotExported.length > 0) {
                this.$refs.notExportedItemsDialog.open()
              }
            })
          }.bind(this),
        })
      } catch (e) {
        console.log(e)
      }
    },
    bulkEditPayDate(date) {
      try {
        if (this.selected.length == 0) {
          Snackbar.error('データを選択してください。')
          this.$refs.bulkUpdatePayDateDialog.stopload()
          return
        }
        let url = this.$store.getters.apiMultiUpdatePayDate
        let data = {
          pay_key_list: this.getSelectedPayKey(),
          organization_id: this.$store.getters.getOrganizationID,
          pay_date: date,
        }
        let params = {
          headers: { Authorization: this.$store.getters.getAuthToken },
        }
        Axios.post(url, data, params).then((response) => {
          this.$refs.bulkUpdatePayDateDialog.stopload()

          if (response.data.status == 'success') {
            this.getTableData(
              false,
              this.selected.map((v) => v.id)
            ).then(() => {
              this.$refs.bulkUpdatePayDateDialog.close()
              Snackbar.success('支払日付の更新が完了しました。')
            })
          } else if (response.data.status == '01') {
            let msg =
              '支払済みのデータは更新できません。更新には支払解除が必要です。'
            Snackbar.error(msg)
          } else if (response.data.status == '02') {
            let msg =
              '支払済みのデータは更新できません。未払のデータのみ選択してくだい。'
            Snackbar.error(msg)
          } else {
            Snackbar.error(response.data.error)
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    approvePaid() {
      try {
        if (this.selected.length == 0) {
          Snackbar.error('データを選択してください。')
          this.$refs.approvePaidDialog.stopload()
          return false
        }
        const hasPrivilege = this.checkPaymentPrivilege('approve5')
        if (!hasPrivilege) {
          this.$refs.approvePaidDialog.stopload()
          Snackbar.error('権限のないデータが含まれています')
          return
        }
        let url = this.$store.getters.apiMultiUpdatePaid
        let data = {
          action: 'approve',
          pay_key_list: this.getSelectedPayKey(),
          user_organization_id: this.$store.getters.getUserOrganizationID,
          organization_id: this.$store.getters.getOrganizationID,
        }
        let params = {
          headers: { Authorization: this.$store.getters.getAuthToken },
        }
        Axios.post(url, data, params).then((response) => {
          this.$refs.approvePaidDialog.stopload()
          if (response.data.status == 'success') {
            let pay_keys = response.data.pay_keys
            for (let item of this.tableData) {
              let found = pay_keys.find((obj) => {
                return item.invoice_id_list.indexOf(obj.id) != -1
              })
              if (!found) {
                continue
              }
              this.$set(item, 'pay_key', found.pay_key)
              this.$set(item, 'date_paid', response.data.date)
              this.$set(item, 'paid_user', response.data.paid_user_name)
            }
            this.$refs.approvePaidDialog.close()
            Snackbar.success('支払済みに変更しました。')
          } else {
            Snackbar.error(response.data.error)
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    cancelPaid() {
      try {
        if (this.selected.length == 0) {
          Snackbar.error('データを選択してください。')
          this.$refs.cancelPaidDialog.stopload()
          return false
        }

        const hasPrivilege = this.checkPaymentPrivilege('approve5')
        if (!hasPrivilege) {
          this.$refs.cancelPaidDialog.stopload()
          Snackbar.error('権限のないデータが含まれています')
          return
        }

        let url = this.$store.getters.apiMultiUpdatePaid

        let data = {
          action: 'cancel',
          pay_key_list: this.getSelectedPayKey(),
          user_organization_id: this.$store.getters.getUserOrganizationID,
          organization_id: this.$store.getters.getOrganizationID,
        }

        let params = {
          headers: { Authorization: this.$store.getters.getAuthToken },
        }

        Axios.post(url, data, params).then((response) => {
          if (response.data.status == 'success') {
            this.getTableData(
              false,
              this.selected.map((v) => v.id)
            ).then(() => {
              this.$refs.cancelPaidDialog.close()
              Snackbar.success('支払を解除しました。')
            })
          } else {
            this.$refs.cancelPaidDialog.stopload()
            Snackbar.error(response.data.error)
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    cancelPayApprove() {
      try {
        if (this.selected.length == 0) {
          Snackbar.error('データを選択してください。')
          this.$refs.cancelPayApproveDialog.stopload()
          return false
        }

        const hasPrivilege = this.checkPaymentPrivilege('approve4')
        if (!hasPrivilege) {
          this.$refs.cancelPayApproveDialog.stopload()
          Snackbar.error('権限のないデータが含まれています')
          return
        }

        for (let item of this.selected) {
          if (item.paid_user) {
            this.$refs.cancelPayApproveDialog.stopload()
            Snackbar.error(
              '支払済みのデータは解除できません。未払のみ選択してください。'
            )
            return false
          }
        }

        let url = this.$store.getters.apiMultiCancelPayApprove

        let data = {
          pay_key_list: this.getSelectedPayKey(),
          user_organization_id: this.$store.getters.getUserOrganizationID,
          organization_id: this.$store.getters.getOrganizationID,
        }

        let params = {
          headers: { Authorization: this.$store.getters.getAuthToken },
        }

        Axios.post(url, data, params).then((response) => {
          if (response.data.status == 'success') {
            this.getTableData(
              false,
              this.selected.map((v) => v.id)
            ).then(() => {
              this.$refs.cancelPayApproveDialog.close()
              Snackbar.success('支払連携を解除しました。')
            })
          } else {
            this.$refs.cancelPayApproveDialog.stopload()
            Snackbar.error(response.data.error)
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    async checkDublicateExport() {
      if (this.selected.length == 0) {
        Snackbar.error('データを選択してください。')
        return false
      }
      if (this.selected.some((item) => item.export_count > 0)) {
        const yes = await this.$confirm({
          message: '出力済みのデータが含まれます。再度出力しますか？',
        })
        if (!yes) {
          return false
        }
      }
      this.$refs.exportJournalDialog.open()
    },
    checkEnableToExport() {
      try {
        this.itemNotExported = []
        let selectedItem = []
        for (let item of this.selected) {
          if (item.pay_amount > 0 && item.pay_type === 1) {
            selectedItem.push(item)
          } else {
            this.itemNotExported.push(item)
          }
        }
        if (selectedItem.length > 0) {
          this.selected = selectedItem
          return true
        } else {
          return false
        }
      } catch (e) {
        console.log(e)
      }
    },
    downloadCSV(encodeType) {
      try {
        this.getTableData().then((getTableResult) => {
          if (getTableResult) {
            const sortItems = this.$refs.sweeepPaymentTable.sortedItems
            downloadPayments(sortItems, encodeType).then((downloadResult) => {
              if (downloadResult) {
                Snackbar.success('成功しました。')
                this.$refs.exportTableDialog.close()
              } else {
                this.$refs.exportTableDialog.stopload()
                Snackbar.error('エラーが発生しました')
              }
            })
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    bulkChangeUserBank(userBankId) {
      try {
        if (this.selected.length == 0) {
          Snackbar.error('データを選択してください。')
          this.$refs.bulkChangeUserBankDialog.stopload()
          return false
        }

        for (let item of this.selected) {
          if (item.paid_user) {
            this.$refs.bulkChangeUserBankDialog.stopload()
            Snackbar.error(
              '支払済みのデータは変更できません。未払のみ選択してください。'
            )
            return false
          }
        }

        const data = {
          pay_key_list: this.getSelectedPayKey(),
          user_bank_id: userBankId,
        }

        this.$store
          .dispatch('bulkChangePaymentUserBank', data)
          .then(() => {
            this.getTableData(
              false,
              this.selected.map((v) => v.id)
            ).then(() => {
              this.$refs.bulkChangeUserBankDialog.close()
              Snackbar.success(this.$t('message.success'))
            })
          })
          .catch((error) => {
            this.$refs.bulkChangeUserBankDialog.stopload()
            Snackbar.error(error.message)
          })
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
