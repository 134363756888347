<template>
  <base-dialog
    ref="dialog"
    max-width="650"
    :title="$t('message.payment_change_user_bank')"
    :cancel-text="$t('message.modal_cancel_btn')"
    :submit-text="$t('message.save')"
    :loading="loading"
    @submit="submit()"
  >
    <template v-slot:card-text>
      <v-form ref="form">
        <v-container>
          <v-row>
            <v-col cols="3">
              <div class="pt-4 pl-4" style="font-size: 12px">
                振込元口座銀行
              </div>
            </v-col>
            <v-col cols="9">
              <v-select
                v-model="value"
                :items="userBankList"
                item-text="fulltext"
                item-value="id"
                label="振込元口座"
                dense
                outlined
                filled
                :rules="[(v) => !!v || '※この項目は必須です']"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
  </base-dialog>
</template>

<script>
export default {
  props: {
    userBankList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      value: '',
    }
  },
  methods: {
    open() {
      this.$refs.dialog.open()
      this.$refs.form.reset()
      this.loading = false
    },
    close() {
      this.$refs.dialog.close()
      this.loading = false
    },
    stopload() {
      this.loading = false
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.$emit('submit', this.value)
      }
    },
  },
}
</script>
