<template>
  <v-container v-if="checkPrivilege('payment:read')" fluid pa-0>
    <v-row no-gutters>
      <v-col cols="2" :style="{ display: displaySideFilter }">
        <v-card tile outlined class="pa-0">
          <payment-side-filter
            :filters="filters"
            :on-click="toggleSideFilter"
            :journal-items="journalItems"
            :get-storage-filters="getLocalStrageFilter"
            :set-storage-filters="setLocalStorageFilter"
            :clear-all-filter="clearAllFilter"
            :user-bank-list="userBankList"
            :style="{ display: displaySideFilter }"
            @change-date="getTableData"
          />
        </v-card>
      </v-col>

      <v-col
        :cols="displaySideFilter == 'block' ? 10 : 12"
        :style="
          displaySideFilter == 'block'
            ? 'min-width:calc(75% + (8.33% - 60px));'
            : 'min-width:calc(100% - 60px);'
        "
      >
        <v-card flat>
          <payment-header-filter
            :onClick="toggleSideFilter"
            :filters="filters"
            :statusData="statusData"
            :filterBtnTargetMonth="filterBtnTargetMonth"
            :filterBtnPrevMonth="filterBtnPrevMonth"
            :filterBtnNextMonth="filterBtnNextMonth"
            :getTableData="getTableData"
            :getStatusData="getStatusData"
            :clearAllFilter="clearAllFilter"
          />
        </v-card>

        <v-card tile outlined>
          <payment-table
            ref="paymentTable"
            :user="user"
            :organization="organization"
            :definition="definition"
            :tableData="tableData"
            :getTableData="getTableData"
            :loading-table="loadingTable"
            :filters="filters"
            :exportFormatList="exportFormatList"
            :user-bank-list="userBankList"
            :multi-user-bank="multiUserBank"
            @editTransferFee="saveTransferFee($event)"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Snackbar from 'Helpers/snackbar/index'
import PaymentHeaderFilter from './components/PaymentHeaderFilter'
import PaymentSideFilter from './components/PaymentSideFilter'
import PaymentTable from './components/Table/PaymentTable'
import axios from 'axios'
import Axios from 'axios'

export default {
  components: {
    PaymentHeaderFilter,
    PaymentSideFilter,
    PaymentTable,
  },
  props: {
    organization: null,
    definition: Object,
    user: null,
    journalItems: Object,
  },
  data() {
    return {
      paymentWidth: 100,
      sideFilterWidth: '0px',
      displaySideFilter: 'none',
      userBankList: [],
      multiUserBank: false,

      tableData: [
        {
          selected: null,
          pay_date: '',
          paid_user: '',
          date_paid: '',
        },
      ],
      exportFormatList: [],
      statusData: [
        {
          pay_date: '',
          notPaid: { count: 0, selected: '' },
          notExported: { count: 0, selected: '' },
          paid: { count: 0, selected: '' },
          exported: { count: 0, selected: '' },
        },
      ],

      loadingTable: false,

      filters: {
        date: '',
        to_date: '',
        search_bar: '',
        company_name: '',
        user_bank: '',
        pay_type: {
          type1: 1,
          type2: 1,
          type3: 1,
          type4: 1,
          type5: 1,
          type6: 1,
          type0: 1,
        },
        gensenzei: '',

        invoice_amount_1: '',
        invoice_amount_2: '',
        pay_amount_1: '',
        pay_amount_2: '',
        gensen_amount_1: '',
        gensen_amount_2: '',

        paid: '',
        exported: '',
        paid_user: '',
        export_user: '',

        bank_code: '',
        bank_branch_code: '',
        bank_account_type: '',
        bank_account_number: '',
        bank_account_name: '',
        idFrom: '',
        idTo: '',
      },
    }
  },
  watch: {
    filters: {
      handler: function (val) {
        this.setLocalStorageFilter(val)
      },
      deep: true,
    },
  },
  created() {
    this.getLocalStrageFilter()
  },
  mounted() {
    this.getStatusData()
    this.getTableData()
    this.getUserBank()
    this.getExportFormatList()
  },
  methods: {
    clearAllFilter() {
      try {
        var t = this
        var setLocalStorage = this.setLocalStorageFilter
        return new Promise(function (resolve) {
          for (let key of Object.keys(t.filters)) {
            let ignore_keys = ['date', 'to_date', 'pay_type']
            if (ignore_keys.includes(key) === false) {
              t.$set(t.filters, key, '')
            }
          }
          for (let key of Object.keys(t.filters.pay_type)) {
            t.$set(t.filters.pay_type, key, 1)
          }
          setLocalStorage()

          resolve(true)
        })
      } catch (e) {
        console.log(e)
      }
    },
    toggleSideFilter() {
      try {
        if (this.displaySideFilter == 'none') {
          this.displaySideFilter = 'block'
          this.sideFilterWidth = '200px'
        } else {
          this.displaySideFilter = 'none'
          this.sideFilterWidth = '0px'
        }
      } catch (e) {
        console.log(e)
      }
    },
    initPayDateForm() {
      try {
        var now = new Date()
        var yyyy = now.getFullYear()
        var mm = ('0' + (now.getMonth() + 1)).slice(-2)
        var dd = ('0' + now.getDate()).slice(-2)

        this.filters.date = yyyy + '-' + mm + '-' + dd
        this.filters.to_date = ''
      } catch (e) {
        console.log(e)
      }
    },
    getStatusData() {
      try {
        Axios.get(this.$store.getters.apiPaymentStatusUrl, {
          headers: {
            Authorization: this.$store.getters.getAuthToken,
          },
          params: {
            organization_id: this.$store.getters.getOrganizationID,
          },
        }).then((response) => {
          this.statusData = response.data.result
        })
      } catch (e) {
        console.log(e)
      }
    },
    getTableData(loading = true, selectedIds = []) {
      try {
        if (loading) {
          this.loadingTable = true
        }

        if (!this.filters.date) {
          this.initPayDateForm()
          this.setLocalStorageFilter()
        }

        return new Promise((resolve) => {
          axios
            .get(this.$store.getters.apiPaymentListFunction, {
              headers: { Authorization: this.$store.getters.getAuthToken },
              params: {
                organization_id: this.$store.getters.getOrganizationID,
                date_from: this.filters.date,
                date_to: this.filters.to_date,
              },
            })
            .then((response) => {
              if (response.data.error) {
                Snackbar.error(response.data.error)
                resolve(false)
              } else {
                const items = response.data.payment_list
                this.tableData.splice(items.length)
                this.multiUserBank = this.checkMultiUserBank()

                for (let i = 0; i < items.length; i++) {
                  let item = items[i]
                  if (selectedIds.includes(item.id)) {
                    item.selected = true
                  }
                  this.$set(this.tableData, i, item)
                }
                if (loading) {
                  this.loadingTable = false
                }
                this.$refs.paymentTable.setPage(1)
                resolve(true)
              }
            })
        })
      } catch (e) {
        console.log(e)
      }
    },
    getExportFormatList() {
      try {
        let url = this.$store.getters.apiExportFormatListUrl
        const auth_token = this.$store.getters.getAuthToken
        url += '?organization_id=' + this.$store.getters.getOrganizationID
        axios
          .get(url, {
            headers: { Authorization: auth_token },
            params: { exclude_hidden: true },
          })
          .then((response) => {
            try {
              this.exportFormatList = response.data.data
            } catch (error) {
              Snackbar.error(error)
            }
          })
      } catch (error) {
        Snackbar.error(error)
      }
    },
    formatDate_firstDay(date) {
      try {
        var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + 1,
          year = d.getFullYear()

        if (month.length < 2) month = '0' + month
        if (day.length < 2) day = '0' + day

        return [year, month, day].join('-')
      } catch (e) {
        console.log(e)
      }
    },
    formatDate_lastDay(date) {
      try {
        var d = new Date(date)
        var month = '' + (d.getMonth() + 1)
        var year = d.getFullYear()
        var day = '' + d.getDate()

        if (month.length < 2) month = '0' + month
        if (day.length < 2) day = '0' + day

        return [year, month, day].join('-')
      } catch (e) {
        console.log(e)
      }
    },
    getDaysInMonth(year, month) {
      try {
        return new Date(year, month, 0).getDate()
      } catch (e) {
        console.log(e)
      }
    },
    filterBtnPrevMonth() {
      try {
        var t = this
        if (t.filters.date) {
          let val_from = new Date(t.filters.date)
          let from_date = val_from.setMonth(val_from.getMonth() - 1)
          let formatted_date = this.formatDate_firstDay(from_date)
          this.filters.date = formatted_date
        }
        if (t.filters.to_date) {
          let to_date = new Date(t.filters.to_date)
          let newData = new Date(
            to_date.getFullYear(),
            to_date.getMonth() - 0,
            0
          )
          this.filters.to_date = this.formatDate_lastDay(newData)
        }
        this.getTableData()
      } catch (e) {
        console.log(e)
      }
    },
    filterBtnNextMonth() {
      try {
        var t = this
        if (t.filters.date) {
          let val_from = new Date(t.filters.date)
          let from_date = val_from.setMonth(val_from.getMonth() + 1)
          let formatted_date = this.formatDate_firstDay(from_date)
          this.filters.date = formatted_date
        }
        if (t.filters.to_date) {
          let to_date = new Date(t.filters.to_date)
          let newData = new Date(
            to_date.getFullYear(),
            to_date.getMonth() + 2,
            0
          )
          this.filters.to_date = this.formatDate_lastDay(newData)
        }
        this.getTableData()
      } catch (e) {
        console.log(e)
      }
    },
    filterBtnTargetMonth() {
      try {
        let value = new Date()
        let month = value.getMonth()
        let year = value.getFullYear()
        let days = this.getDaysInMonth(year, month + 1)
        let prevMonthDate = new Date(year, month, days)
        let formatted_date = this.formatDate_firstDay(prevMonthDate)
        this.filters.date = formatted_date

        let newData = new Date()
        newData.setDate(days)
        this.filters.to_date = this.formatDate_lastDay(prevMonthDate)
        this.getTableData()
      } catch (e) {
        console.log(e)
      }
    },
    setLocalStorageFilter() {
      localStorage.setItem('paymentFilter', JSON.stringify(this.filters))
    },
    getLocalStrageFilter() {
      //最初はLocalStorageにない。
      const localStorageFilter = JSON.parse(
        localStorage.getItem('paymentFilter')
      )
      if (localStorageFilter !== null) {
        this.$set(this, 'filters', { ...this.filters, ...localStorageFilter })
      }
    },
    getUserBank() {
      try {
        this.$store.dispatch('getUserBankList').then((data) => {
          this.userBankList = data
        })
      } catch (e) {
        console.log(e)
      }
    },
    checkMultiUserBank() {
      if (this.tableData.length == 0) {
        return false
      }
      const firstData = this.tableData[0]
      if (firstData.user_bank) {
        const firstBankId = firstData.user_bank.id
        const multiBank = this.tableData.some(
          (item) => !item.user_bank || item.user_bank.id != firstBankId
        )
        return multiBank
      } else {
        const multiBank = this.tableData.some(
          (item) => item.user_bank && item.user_bank.id
        )
        return multiBank
      }
    },
    saveTransferFee(payload) {
      try {
        const transfer_fee = payload.transfer_fee
        const pay_key = payload.pay_key
        const data = {
          pay_key: pay_key,
          transfer_fee: transfer_fee,
        }
        this.$store
          .dispatch('updatePaymentDetail', data)
          .then(() => {
            for (const [i, data] of this.tableData.entries()) {
              if (data.pay_key === pay_key) {
                const pay_amount_deduct_transfer_fee =
                  data.pay_amount - transfer_fee
                this.$set(this.tableData[i], 'transfer_fee', transfer_fee)
                this.$set(
                  this.tableData[i],
                  'pay_amount_deduct_transfer_fee',
                  pay_amount_deduct_transfer_fee
                )
                break
              }
            }
            this.$refs.paymentTable.$refs.sweeepPaymentTable.closeEditTransferFee()
            Snackbar.success(this.$t('message.success'))
          })
          .catch((error) => {
            Snackbar.error(error.message)
          })
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
