<template>
  <div>
    <vue-perfect-scrollbar :settings="settings">
      <v-card>
        <v-toolbar flat class="border-bottom-1" height="50">
          支払情報
        </v-toolbar>
        <v-form ref="form" lazy-validation>
          <v-container fulid>
            <v-card
              tile
              outlined
              class="h-100 pa-4"
              style="background-color: #fcfcfc"
            >
              <v-row no-gutters>
                <v-col cols="7">
                  <v-text-field
                    v-model="formPayment.company_name"
                    label="請求元"
                    :readonly="true"
                    class="px-1"
                  />
                </v-col>
                <v-col cols="3">
                  <date-pick-input
                    v-model="formPayment.pay_date"
                    label="支払日付"
                    :flat="false"
                    :editable="false"
                    class="px-1"
                  />
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    :value="paymentTypeContent"
                    label="支払方法"
                    :readonly="true"
                    class="px-1"
                  />
                </v-col>
              </v-row>
              <v-row v-if="settingPaidByCustomer" no-gutters class="py-1">
                <v-text-field
                  v-model="formPayment.user_bank_fulltext"
                  class="more-dense"
                  hide-details
                  dense
                  filled
                  item-text="fulltext"
                  item-value="id"
                  placeholder="振込元金融機関"
                  readonly
                />
              </v-row>
              <v-row v-if="settingPaidByCustomer" no-gutters class="py-1">
                <v-col cols="5">
                  <v-checkbox
                    v-model="formPayment.paid_by_customer"
                    label="振込手数料先方負担"
                    dense
                    hide-details
                    class="mt-0"
                    readonly
                  />
                </v-col>
                <v-col cols="7" />
              </v-row>
              <v-row no-gutters class="py-1">
                <v-autocomplete
                  v-model="formPayment.bank_name"
                  :items="bankList"
                  dense
                  filled
                  hide-details
                  item-text="name_code"
                  item-value="name"
                  placeholder="金融機関"
                  class="more-dense"
                  readonly
                />
              </v-row>
              <v-row no-gutters class="py-1">
                <v-autocomplete
                  v-model="formPayment.bank_branch_name"
                  :items="branchList"
                  dense
                  filled
                  hide-details
                  placeholder="支店"
                  class="more-dense"
                  item-text="name_code"
                  item-value="name"
                  readonly
                />
              </v-row>
              <v-row no-gutters class="py-1">
                <v-btn-toggle
                  v-model="formPayment.bank_account_type"
                  mandatory
                  dense
                  color="blue"
                >
                  <v-btn
                    small
                    outlined
                    value="普通"
                    :disabled="formPayment.bank_account_type != '普通'"
                    v-text="'普通'"
                  />
                  <v-btn
                    small
                    outlined
                    value="当座"
                    :disabled="formPayment.bank_account_type != '当座'"
                    v-text="'当座'"
                  />
                  <v-btn
                    small
                    outlined
                    value="貯蓄"
                    :disabled="formPayment.bank_account_type != '貯蓄'"
                    v-text="'貯蓄'"
                  />
                </v-btn-toggle>
              </v-row>
              <v-row no-gutters class="py-1">
                <v-text-field
                  v-model="formPayment.bank_account_number"
                  dense
                  filled
                  hide-details
                  maxlength="7"
                  placeholder="口座番号"
                  class="more-dense"
                  readonly
                />
              </v-row>
              <v-row no-gutters class="py-1">
                <v-text-field
                  v-model="formPayment.bank_account_name"
                  dense
                  filled
                  hide-details
                  placeholder="名義人名"
                  class="more-dense"
                  readonly
                />
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    slot="activator"
                    v-model="formPayment.invoice_amount"
                    class="mx-2 align-right"
                    label="請求額(円)"
                    placeholder=" "
                    tabindex=""
                    suffix=" "
                    readonly
                    hide-details
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    slot="activator"
                    v-model="formPayment.gensen_amount"
                    class="mx-2 align-right"
                    label="源泉税額(円)"
                    placeholder=" "
                    tabindex=""
                    suffix=" "
                    readonly
                    hide-details
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    slot="activator"
                    v-model="formPayment.pay_amount"
                    class="mx-2 align-right"
                    label="支払額(円)"
                    placeholder=" "
                    tabindex=""
                    suffix=" "
                    readonly
                    hide-details
                  />
                </v-col>
              </v-row>
              <v-row v-if="settingPaidByCustomer">
                <v-col cols="4">
                  <v-text-field
                    slot="activator"
                    v-model="formPayment.transfer_fee"
                    class="mx-2 align-right"
                    label="先方負担振込手数料(円)"
                    placeholder=" "
                    tabindex=""
                    suffix=" "
                    readonly
                    hide-details
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    slot="activator"
                    v-model="formPayment.pay_amount_deduct_transfer_fee"
                    class="mx-2 align-right"
                    label="振込手数料控除後支払額(円)"
                    placeholder=" "
                    tabindex=""
                    suffix=" "
                    readonly
                    hide-details
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-container>
          <v-container v-if="settingPayJournal">
            <journal-form-header no-padding />
            <v-row
              v-for="(journal, i) in wrapedJournals"
              :key="`payment_detail_journal_${i}`"
              no-gutters
            >
              <v-col
                v-for="entrySide in ['debit', 'credit']"
                :key="`${entrySide}_${i}`"
                cols="6"
              >
                <v-card
                  tile
                  outlined
                  class="pb-3"
                  style="background-color: #fcfcfc"
                >
                  <div class="px-2 pt-2">
                    <journal-iterators
                      :journal="journal"
                      :editable="false"
                      :entry-side="entrySide"
                    />
                  </div>
                  <journal-amount-field
                    :value="
                      entrySide === 'debit' ? totalDebitTax : totalCreditTax
                    "
                    :label="entrySide === 'debit' ? '貸方税額' : '借方税額'"
                    :editable="false"
                  />
                  <journal-amount-field
                    :value="entrySide === 'debit' ? totalDebit : totalCredit"
                    :label="entrySide === 'debit' ? '貸方合計' : '借方合計'"
                    :editable="false"
                  />
                </v-card>
              </v-col>
              <v-row v-if="showJournalTextArea" no-gutters>
                <v-col cols="12">
                  <v-card
                    tile
                    outlined
                    class="pa-3"
                    style="background-color: #fcfcfc"
                  >
                    <journal-text-field
                      v-if="displaySetting.description"
                      description-field
                      :value="journal.description"
                      :chip="sliceText(definition.description, 0, 4)"
                      :tooltip="definition.description"
                      :editable="false"
                    />
                    <journal-text-field
                      v-if="displaySetting.free_text_1"
                      :value="journal.free_text_1"
                      :chip="sliceText(definition.free_text_1, 0, 4)"
                      :tooltip="definition.free_text_1"
                      :editable="false"
                    />
                    <journal-text-field
                      v-if="displaySetting.free_text_2"
                      :value="journal.free_text_2"
                      :chip="sliceText(definition.free_text_2, 0, 4)"
                      :tooltip="definition.free_text_2"
                      :editable="false"
                    />
                  </v-card>
                </v-col>
              </v-row>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DatePickInput from 'Components/Input/DatePickInput'
import JournalFormHeader from 'Components/Journal/JournalFormHeader.vue'
import JournalIterators from 'Components/Journal/JournalIterators.vue'
import JournalAmountField from 'Components/Journal/JournalAmountField.vue'
import JournalTextField from 'Components/Journal/JournalTextField.vue'
import { correctMasterListByName } from '@/helpers/payment/index'
import { sliceText } from 'Helpers/journal'
import { computeDescription } from 'Helpers/invoice/index'
import { convertPaymentType } from 'Helpers/payment/index'

export default {
  components: {
    DatePickInput,
    JournalFormHeader,
    JournalIterators,
    JournalAmountField,
    JournalTextField,
  },
  props: {
    definition: {
      type: Object,
      required: true,
    },
    formPayment: {
      type: Object,
      required: true,
    },
    invoiceSummaryList: {
      type: Array,
      required: true,
    },
    paymentJournalList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      editFlag: false,
      hoge: false,
      showTooltip: false,
      input_DELETE: '',
      openBankTable: false,
      selected: [],
      headers: [],
      settings: {
        maxScrollbarLength: 100,
      },
    }
  },
  computed: {
    ...mapGetters([
      'displaySetting',
      'bankAccountTypeList',
      'showJournalTextArea',
      'settingPayJournal',
      'settingPaidByCustomer',
    ]),
    totalDebit: function () {
      return this.calc_totalDebit()
    },
    totalCredit: function () {
      return this.calc_totalCredit()
    },
    totalDebitTax: function () {
      return this.calc_totalDebitTax()
    },
    totalCreditTax: function () {
      return this.calc_totalCreditTax()
    },
    wrapedJournals: function () {
      let jouranls = []
      let wrapedJouranl = {}
      this.formPayment.payment_journals.forEach((jouranl) => {
        wrapedJouranl = { ...jouranl }
        wrapedJouranl.debit_tax = wrapedJouranl.debit_tax_class
        wrapedJouranl.credit_tax = wrapedJouranl.credit_tax_class
        wrapedJouranl.description = computeDescription(
          wrapedJouranl.description,
          this.formPayment.issue_date
        )
        jouranls.push(wrapedJouranl)
      })
      return jouranls
    },
    paymentTypeContent: function () {
      try {
        let payType = convertPaymentType(this.formPayment.pay_type)
        if (payType.content) {
          return payType.content
        } else {
          return ''
        }
      } catch (e) {
        console.log(e)
        return ''
      }
    },
    bankList() {
      const bankName = this.formPayment.bank_name ? this.formPayment.bank_name: ''
      const bankCode = this.formPayment.bank_code ? this.formPayment.bank_code: ''
      let bankList =  this.$store.getters.bankList
      bankList = correctMasterListByName(bankList, bankName, bankCode)
      return bankList
    },
    branchList() {
      const bankName = this.formPayment.bank_name ? this.formPayment.bank_name: ''
      let branchList = this.$store.getters.bankBranchList[bankName]
      branchList = branchList ? branchList: []
      const branchName = this.formPayment.bank_branch_name ? this.formPayment.bank_branch_name: ''
      const branchCode = this.formPayment.bank_branch_code ? this.formPayment.bank_branch_code: ''
      branchList = correctMasterListByName(branchList, branchName, branchCode)
      return branchList
    },
  },
  methods: {
    getName(obj) {
      try {
        return obj.name
      } catch (e) {
        return ''
      }
    },
    getCode(obj) {
      try {
        return obj.code
      } catch (e) {
        return ''
      }
    },
    getNameCode(obj) {
      try {
        return obj.name + ' ' + obj.code
      } catch (e) {
        return ''
      }
    },
    calc_totalDebit() {
      try {
        let total = 0
        for (var x = 0; x < this.paymentJournalList.length; x++) {
          var val = 0
          try {
            val = parseFloat(
              this.paymentJournalList[x].debit_amount.replace(/,/g, '')
            )
          } catch (err) {}
          if (!val) {
            val = 0
          }
          total = total + val
        }
        return this.$options.filters.currency(total)
      } catch (e) {}
    },
    calc_totalCredit() {
      try {
        let total = 0
        for (var x = 0; x < this.paymentJournalList.length; x++) {
          var val = 0
          try {
            val = parseFloat(
              this.paymentJournalList[x].credit_amount.replace(/,/g, '')
            )
          } catch (err) {}
          if (!val) {
            val = 0
          }
          total = total + val
        }
        return this.$options.filters.currency(total)
      } catch (e) {
        console.log(e)
      }
    },
    calc_totalDebitTax() {
      try {
        let total = 0
        for (var x = 0; x < this.paymentJournalList.length; x++) {
          var val = 0
          try {
            val = parseFloat(
              this.paymentJournalList[x].debit_tax_amount.replace(/,/g, '')
            )
          } catch (err) {}
          if (!val) {
            val = 0
          }
          total = total + val
        }
        return this.$options.filters.currency(total)
      } catch (e) {
        console.log(e)
      }
    },
    calc_totalCreditTax() {
      try {
        let total = 0
        for (var x = 0; x < this.paymentJournalList.length; x++) {
          var val = 0
          try {
            val = parseFloat(
              this.paymentJournalList[x].credit_tax_amount.replace(/,/g, '')
            )
          } catch (err) {}
          if (!val) {
            val = 0
          }
          total = total + val
        }
        return this.$options.filters.currency(total)
      } catch (e) {
        console.log(e)
      }
    },
    getScrollHeight() {
      return 'height: calc(100vh - 50px) !important'
    },
    sliceText(text, i, h) {
      return sliceText(text, i, h)
    },
  },
}
</script>
