<template>
  <div>
    <v-menu
      offset-x
      left
      z-index="99"
      transition="slide-x-transition"
      nudge-left="20"
      close-delay="300"
    >
      <template v-slot:activator="{ on, attrs }">
        <span v-on="on" v-bind="attrs" v-tooltip="'並び替えを指定'">
          {{ title }}
        </span>
      </template>

      <div>
        <v-card class="sweeep-popover">
          <v-card-title> 並び替え </v-card-title>
          <v-list dense class="fs-11">
            <div v-for="(item, i) in sortItems" :key="i">
              <v-list-item
                link
                @click="$emit('click', { value: item.value, mode: item.mode })"
              >
                <i :class="'mr-3 ' + item.icon" />
                {{ item.text }}
              </v-list-item>
            </div>
          </v-list>
        </v-card>
      </div>
    </v-menu>
    <span v-show="matchObj.text" v-tooltip="matchObj.text" class="ml-2">
      <span v-show="sortParams.mode === 'asc'">
        <s-icon icon="ti-arrow-up" />
      </span>
      <span v-show="sortParams.mode === 'desc'">
        <s-icon icon="ti-arrow-down" />
      </span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    sortParams: {
      type: Object,
      default: () => {
        return {}
      },
    },
    sortItems: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  computed: {
    matchObj: function () {
      try {
        let value = this.sortParams.value
        const mode = this.sortParams.mode
        if (
          value === 'bank_account_name' &&
          this.sortParams.options.replaceCompanyType
        ) {
          value = this.sortParams.value + '.1'
        }
        const obj = this.sortItems.find(
          (obj) => obj.value === value && obj.mode === mode
        )
        return obj ? obj : {}
      } catch (e) {
        return {}
      }
    },
  },
}
</script>
