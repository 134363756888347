<template>
  <v-card class="sweeep-dialog">
    <v-toolbar flat class="border-bottom-1" height="50">
      経費仕訳
      <template v-for="(item, index) in invoiceList">
        <v-btn
          :key="index"
          x-small
          fab
          depressed
          class="ml-2"
          href="#"
          @click="scrollToInvoice(index)"
        >
          {{ index + 1 }}
        </v-btn>
      </template>
    </v-toolbar>

    <vue-perfect-scrollbar
      ref="scroll"
      class=""
      :style="scrollHeight"
      style="overflow: auto !important"
    >
      <div
        v-for="(item, index) in invoiceList"
        :id="['invoice_summary_' + index]"
        :key="item.local_id"
      >
        <div class="pa-3">
          <div class="pa-3" style="background: #fcfcfc; border: 1px solid #ccc">
            <v-btn icon small color="light"> {{ index + 1 }} </v-btn
            ><span class="ml-2 has-link fw-bold" @click="toInvoice(item)"
              ># {{ item.local_id }}</span
            >
            <v-layout
              v-if="item.credit_account_title_name"
              flat
              class="mt-3"
              height="40"
            >
              <v-flex xs2>
                <v-text-field
                  v-model="item.issue_date"
                  class="fs-10 mx-2"
                  label="伝票日付"
                  readonly
                />
              </v-flex>
              <v-flex xs4>
                <v-text-field
                  v-model="item.debit_account_title_name"
                  class="fs-10 mx-2"
                  label="借方科目"
                  readonly
                  placeholder=" "
                />
              </v-flex>
              <v-flex xs2>
                <v-text-field
                  slot="activator"
                  v-model="item.invoice_amount"
                  class="mx-2 align-right"
                  label="請求額(円)"
                  placeholder=" "
                  tabindex=""
                  suffix=" "
                  readonly
                />
              </v-flex>

              <v-flex xs2>
                <v-text-field
                  slot="activator"
                  v-model="item.gensen_amount"
                  class="mx-2 align-right"
                  label="源泉税額(円)"
                  placeholder=" "
                  tabindex=""
                  suffix=" "
                  readonly
                />
              </v-flex>

              <v-flex xs2>
                <v-text-field
                  slot="activator"
                  v-model="item.pay_amount"
                  class="mx-2 align-right"
                  label="支払額(円)"
                  placeholder=" "
                  tabindex=""
                  suffix=" "
                  readonly
                />
              </v-flex>
            </v-layout>
          </div>

          <payment-invoice-image
            :pdf-url="item.pdfUrl ? item.pdfUrl : ''"
            :loading="!item.image_loaded"
            @download-file="$emit('download-file', item.local_id)"
            @load="$emit('load')"
          />
        </div>
      </div>
    </vue-perfect-scrollbar>
  </v-card>
</template>

<script>
import PaymentInvoiceImage from './PaymentInvoiceImage'

export default {
  components: {
    PaymentInvoiceImage,
  },
  props: {
    invoiceList: {
      type: Array,
      required: true,
    },
  },
  computed: {
    totalAmount: function () {
      var sum = function (arr) {
        return arr.reduce(function (prev, current, i, arr) {
          return prev + current
        })
      }

      var amount_list = this.invoiceList.map((item) =>
        this.checkInt(item.invoice_max_amount)
      )
      var total = sum(amount_list)

      return this.$options.filters.currency(total)
    },
    scrollHeight: function () {
      return 'height: calc(100vh - 50px)!important'
    },
  },
  methods: {
    checkInt(amount) {
      try {
        return parseFloat(amount.replace(/,/g, ''))
      } catch (e) {
        return 0
      }
    },
    toInvoice(item) {
      let invoice_layout = {
        showExported: true,
        displayMode: 2,
      }
      localStorage.setItem('invoice_layout', JSON.stringify(invoice_layout))
      var drawer_status = JSON.parse(localStorage['drawer_status'])
      drawer_status[0].display_mode = 2
      localStorage.setItem('drawer_status', JSON.stringify(drawer_status))

      var url =
        location.origin + '/invoice/' + item.local_id + '?modedetail=true'
      window.open(url, '_blank', 'width=1200,height=800')
    },
    scrollToInvoice(index) {
      var element = document.querySelector('#invoice_summary_' + index)
      var pos = element.offsetTop
      this.$refs.scroll.$el.scrollTop = pos
    },
  },
}
</script>
