<template>
  <v-dialog v-model="dialog" max-width="350">
    <v-card class="sweeep-dialog">
      <v-card-title v-text="'振込データのダウンロード'" />
      <v-card-text>
        <div class="mb-5">
          {{ numOfData }}件の振込データをダウンロードします。
        </div>
        <v-select
          v-model="fileType"
          :items="fileTypeItems"
          label="ファイルタイプ"
          item-text="displayName"
          item-value="value"
          dense
          outlined
          filled
          :rules="[(v) => !!v || '※この項目は必須です']"
        />
        <div class="fs-10">
          ・振込以外の支払データ（クレジットなど）は除外されます。<br />
          ・総合振込データ（全銀協フォーマット）で作成されます。
        </div>
      </v-card-text>

      <v-card-actions class="pt-0">
        <v-spacer />
        <v-btn @click.native="close">
          {{ $t('message.modal_cancel_btn') }}
        </v-btn>
        <v-btn color="primary" :loading="loading" @click="submit">
          ダウンロード
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    numOfData: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      fileType: 'txt',
      fileTypeItems: [
        {value: 'txt', displayName: '全銀フォーマット（テキスト形式）'},
        {value: 'csv', displayName: '全銀フォーマット（CSV形式）'}
      ]
    }
  },
  methods: {
    open() {
      this.stopload()
      this.dialog = true
    },
    close() {
      this.stopload()
      this.dialog = false
    },
    startload() {
      this.loading = true
    },
    stopload() {
      this.loading = false
    },
    submit() {
      this.startload()
      this.$emit('submit', this.fileType)
    },
  },
}
</script>
