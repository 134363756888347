<template>
  <div>
    <v-menu v-model="menu" nudge-bottom="40" transition="slide-y-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" small outlined class="grey lighten-3" v-on="on">
          {{ selectedYear }}
        </v-btn>
      </template>
      <v-card>
        <v-list dense>
          <v-list-item link dense class="fs-11" @click="changeSelectedYear(-1)">
            前年
          </v-list-item>
          <v-list-item link dense class="fs-11" @click="changeSelectedYear(1)">
            翌年
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
    <v-btn-toggle dense active-class="active-btn-opacity" class="ml-2">
      <v-btn
        v-for="i in 12"
        :key="'month_' + i"
        :class="computedButtonClass[i - 1]"
        small
        :dark="computedButtonClass[i - 1] == 'blue'"
        class="px-0"
        style="min-width: 30px"
        @click="selectMonth(selectedYear, i)"
      >
        {{ i }}
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
import {
  formatDateWithHyphen,
  convertHyphenFormatToDate,
} from 'Helpers/date/index'

export default {
  props: {
    dateFrom: {
      type: String,
      required: true,
    },
    dateTo: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      menu: false,
      selectedYear: 2020,
    }
  },
  computed: {
    computedDateFromTo: function () {
      return [this.dateFrom, this.dateTo]
    },
    computedButtonClass: function () {
      try {
        let classArray = new Array(12)
        const dateFrom = convertHyphenFormatToDate(this.dateFrom)
        const dateTo = convertHyphenFormatToDate(this.dateTo)
        if (dateFrom <= dateTo) {
          for (let i = 1; i < 13; i++) {
            let [monthFirstDay, monthLastDay] =
              this.getFirstAndLastDayOfTheMonth(this.selectedYear, i)
            if (dateFrom <= monthFirstDay && dateTo >= monthLastDay) {
              //all month round
              classArray[i - 1] = 'blue'
            } else if (
              (monthFirstDay <= dateFrom && dateFrom <= monthLastDay) ||
              (monthFirstDay <= dateTo && dateTo <= monthLastDay)
            ) {
              //a part of the month
              classArray[i - 1] = 'blue lighten-4'
            } else {
              classArray[i - 1] = ''
            }
          }
        }
        return classArray
      } catch (e) {
        console.log(e)
        return ['', '', '', '', '', '', '', '', '', '', '', '']
      }
    },
  },
  watch: {
    computedDateFromTo() {
      this.setSelectedYear()
    },
  },
  mounted() {
    this.setSelectedYear()
  },
  methods: {
    setSelectedYear() {
      if (this.dateTo) {
        this.selectedYear = Number(this.dateTo.slice(0, 4))
      } else if (this.dateFrom) {
        this.selectedYear = Number(this.dateFrom.slice(0, 4))
      } else {
        const currentDateTime = new Date()
        this.selectedYear = currentDateTime.getFullYear()
      }
    },
    changeSelectedYear(addNum) {
      this.selectedYear = Number(this.selectedYear + addNum)
    },
    selectMonth(year, month) {
      try {
        const [dateFrom, dateTo] = this.getFirstAndLastDayOfTheMonth(
          year,
          month
        )
        const formatDateFrom = formatDateWithHyphen(dateFrom)
        const formatDateTo = formatDateWithHyphen(dateTo)
        this.$emit('select', { dateFrom: formatDateFrom, dateTo: formatDateTo })
      } catch (e) {
        console.log(e)
      }
    },
    getFirstAndLastDayOfTheMonth(year, month) {
      try {
        const firstDay = new Date(year, month - 1, '01')
        const lastDay = new Date(
          firstDay.getFullYear(),
          firstDay.getMonth() + 1,
          0
        )
        return [firstDay, lastDay]
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
