<template>
  <v-dialog v-model="dialog" max-width="650">
    <v-card class="sweeep-dialog">
      <v-card-title v-text="'注意'" />
      <v-card-text>
        支払金額が0円以下、または支払方法が振込ではないデータがありました。
        <br />同データを除外して出力しました。請求元: {{ excludedCompanyNames }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="close"> 閉じる </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    excludedCompanyNames: function () {
      let text = ''
      if (this.items.length > 0) {
        this.items.forEach((v) => {
          text += v.company_name + ', '
        })
        text = text.slice(0, -2)
      }
      return text
    },
  },
  methods: {
    open() {
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
  },
}
</script>
