<template>
  <v-dialog v-model="dialog" max-width="350">
    <v-card class="sweeep-dialog">
      <v-card-title v-text="$t('message.invoice_list_all_export')" />
      <v-card-text>
        <div class="mb-5">現在表示されているデータをCSV出力します。</div>
        <v-select
          v-model="encodeType"
          :items="encodeTypes"
          label="文字コード"
          dense
          outlined
          filled
          class="small-input"
          :rules="[(v) => !!v || '※この項目は必須です']"
        />
        <div class="fs-10">
          ・EXCELで表示する場合はShift-JISを指定してください。
        </div>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer />
        <v-btn @click.native="close">
          {{ $t('message.modal_cancel_btn') }}
        </v-btn>
        <v-btn :loading="loading" color="primary" @click="submit">
          {{ $t('message.payment_table_export') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      encodeTypes: ['Shift-JIS', 'utf-8'],
      encodeType: 'Shift-JIS',
    }
  },
  methods: {
    open() {
      this.stopload()
      this.dialog = true
    },
    close() {
      this.stopload()
      this.dialog = false
    },
    startload() {
      this.loading = true
    },
    stopload() {
      this.loading = false
    },
    submit() {
      this.startload()
      this.$emit('submit', this.encodeType)
    },
  },
}
</script>
