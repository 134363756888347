<template>
  <vue-perfect-scrollbar class="h-without-header sweeep-filter">
    <v-toolbar height="50">
      <v-btn
        small
        depressed
        color="grey lighten-2"
        @click="clearAllFilter"
        v-tooltip="'日付以外のフィルタを<br>全てクリアします'"
        v-text="'クリア'"
      />
      <v-spacer></v-spacer>
      <v-btn icon @click="onClick()">
        <s-icon icon="feather-chevron-left" size="lg" />
      </v-btn>
    </v-toolbar>

    <v-expansion-panels flat tile multiple focusable style="box-shadow: none">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="fs-10 fw-bold">基本項目</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-4">
          <v-row no-gutters class="mb-4">
            <v-col cols="5">
              <v-text-field
                v-model="filters.idFrom"
                label="支払No."
                filled
                outlined
                dense
                hide-details
                prefix="P"
                class="align-right"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <div class="text-center pt-2">〜</div>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="filters.idTo"
                label="支払No."
                filled
                outlined
                dense
                hide-details
                prefix="P"
                class="align-right"
              ></v-text-field>
            </v-col>
            <div class="ml-3 fs-10">* 0埋め不要です</div>
          </v-row>

          <v-row no-gutters>
            <v-col cols="9">
              <v-menu
                :close-on-content-click="true"
                lazy
                transition="scale-transition"
                offset-x
                full-width
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="filters.date"
                    label="支払日付"
                    filled
                    outlined
                    dense
                    hide-details
                    readonly
                    clearable-icon="feather-x"
                    v-on="on"
                  ></v-text-field>
                </template>

                <v-date-picker
                  v-model="filters.date"
                  locale="jp-ja"
                  no-title
                  :day-format="(date) => new Date(date).getDate()"
                  @input="onInpuDate"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3">
              <div class="fs-10 pt-3">から</div>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="9">
              <v-menu
                :close-on-content-click="true"
                lazy
                transition="scale-transition"
                offset-x
                full-width
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="filters.to_date"
                    label="支払日付"
                    filled
                    outlined
                    dense
                    hide-details
                    readonly
                    clearable-icon="feather-x"
                    v-on="on"
                  ></v-text-field>
                </template>

                <v-date-picker
                  v-model="filters.to_date"
                  locale="jp-ja"
                  no-title
                  :day-format="(date) => new Date(date).getDate()"
                  @input="onInpuDate"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3">
              <div class="fs-10 pt-3">まで</div>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col>
              <v-text-field
                v-model="filters.company_name"
                :label="$t('message.payment_table_business_partner')"
                filled
                outlined
                dense
                hide-details
                clearable
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col>
              <v-autocomplete
                v-if="userBankList.length > 1"
                :items="userBankList"
                v-model="filters.user_bank"
                label="振込元銀行"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="simpletext"
                item-value="id"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col>
              <v-autocomplete
                :items="gensen_items"
                v-model="filters.gensenzei"
                label="源泉対象"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="name"
                item-value="id"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="5">
              <v-text-field
                v-model="filters.invoice_amount_1"
                label="請求額"
                filled
                outlined
                dense
                hide-details
                class="align-right"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <div class="text-center pt-2">〜</div>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="filters.invoice_amount_2"
                label="請求額"
                filled
                outlined
                dense
                hide-details
                class="align-right"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="5">
              <v-text-field
                v-model="filters.pay_amount_1"
                label="支払額"
                filled
                outlined
                dense
                hide-details
                class="align-right"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <div class="text-center pt-2">〜</div>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="filters.pay_amount_2"
                label="支払額"
                filled
                outlined
                dense
                hide-details
                class="align-right"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="5">
              <v-text-field
                v-model="filters.gensen_amount_1"
                label="源泉税額"
                filled
                outlined
                dense
                hide-details
                class="align-right"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <div class="text-center pt-2">〜</div>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="filters.gensen_amount_2"
                label="源泉税額"
                filled
                outlined
                dense
                hide-details
                class="align-right"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col>
              <div class="fs-10 fw-bold my-3 ml-1">支払方法</div>
              <div>
                <v-checkbox
                  v-model="filters.pay_type.type1"
                  color="blue"
                  hide-details
                  class="mt-0"
                  label="振込"
                  :value="1"
                ></v-checkbox>
                <v-checkbox
                  v-model="filters.pay_type.type2"
                  color="blue"
                  hide-details
                  class="my-0"
                  label="引落"
                  :value="1"
                ></v-checkbox>
                <v-checkbox
                  v-model="filters.pay_type.type3"
                  color="blue"
                  hide-details
                  class="my-0"
                  label="クレジット"
                  :value="1"
                ></v-checkbox>
                <v-checkbox
                  v-model="filters.pay_type.type4"
                  color="blue"
                  hide-details
                  class="my-0"
                  label="手形"
                  :value="1"
                ></v-checkbox>
                <v-checkbox
                  v-model="filters.pay_type.type5"
                  color="blue"
                  hide-details
                  class="my-0"
                  label="現金"
                  :value="1"
                ></v-checkbox>
                <v-checkbox
                  v-model="filters.pay_type.type6"
                  color="blue"
                  hide-details
                  class="my-0"
                  label="海外送金"
                  :value="1"
                ></v-checkbox>
                <v-checkbox
                  v-model="filters.pay_type.type0"
                  color="blue"
                  hide-details
                  class="my-0"
                  label="その他"
                  :value="1"
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="fs-10 fw-bold">振込先口座</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-4">
          <v-row no-gutters>
            <v-col>
              <v-autocomplete
                v-model="filters.bank_code"
                :items="$store.getters.bankList"
                label="金融機関"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="name_code"
                item-value="code"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col>
              <v-autocomplete
                v-model="filters.bank_branch_code"
                :items="bankBranchList"
                label="支店"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="name_code"
                item-value="code"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col>
              <v-autocomplete
                :items="$store.getters.bankAccountTypeList"
                v-model="filters.bank_account_type"
                label="口座種別"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="name_code"
                item-value="name"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col>
              <v-text-field
                v-model="filters.bank_account_number"
                label="口座番号"
                filled
                outlined
                dense
                hide-details
                clearable
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col>
              <v-text-field
                v-model="filters.bank_account_name"
                label="口座名義"
                filled
                outlined
                dense
                hide-details
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          <div class="fs-10 fw-bold">ステータス／ユーザ</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-4">
          <v-row no-gutters>
            <v-col>
              <v-autocomplete
                :items="paid_items"
                v-model="filters.paid"
                label="支払済"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="name"
                item-value="id"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col>
              <v-autocomplete
                :items="exported_items"
                v-model="filters.exported"
                label="支払仕訳エクスポート"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="name"
                item-value="id"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col>
              <v-autocomplete
                :items="user_list"
                v-model="filters.paid_user"
                label="支払ユーザ"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="full_name"
                item-value="full_name"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col>
              <v-autocomplete
                :items="user_list"
                v-model="filters.export_user"
                label="支払仕訳エクスポートユーザ"
                filled
                outlined
                dense
                hide-details
                clearable
                item-text="full_name"
                item-value="full_name"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </vue-perfect-scrollbar>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import Snackbar from 'Helpers/snackbar/index'
import { findName } from '@/helpers/bank/index'

export default {
  data() {
    return {
      user_list: [],
      pay_types: [
        { id: 1, name: '振込' },
        { id: 2, name: '引落' },
        { id: 3, name: 'クレジット' },
        { id: 4, name: '手形' },
        { id: 5, name: '現金' },
        { id: 6, name: '海外送金' },
        { id: 0, name: 'その他' },
      ],
      gensen_items: [
        { id: 1, name: '源泉対象' },
        { id: 0, name: '対象外' },
      ],
      paid_items: [
        { id: 0, name: '未払' },
        { id: 1, name: '支払済' },
      ],
      exported_items: [
        { id: 0, name: '仕訳未出力' },
        { id: 1, name: '仕訳出力済' },
      ],
      upload_type_list: [
        { id: 1, name: '画面' },
        { id: 2, name: 'メール' },
        //   { id:3, name:'API' },
        //   { id:4, name:'アプリ' }
      ],
    }
  },
  props: {
    onClick: Function,
    filters: Object,
    journalItems: Object,
    getStorageFilters: Function,
    setStorageFilters: Function,
    clearAllFilter: Function,
    userBankList: Array,
  },
  computed: {
    bankBranchList() {
      try {
        const bankList = this.$store.getters.bankList
        const bankBranchList = this.$store.getters.bankBranchList
        const bankCode = this.filters.bank_code
        const bankName = findName(bankCode, bankList)
        return bankBranchList[bankName]
      } catch {
        return []
      }
    },
    onInpuDate() {
      this.$emit('change-date')
    },
  },
  mounted() {
    try {
      const auth_token = this.$store.getters.getAuthToken
      const organization_id = this.$store.getters.getOrganizationID

      axios
        .get(this.$store.getters.apiUserListFunction, {
          headers: { Authorization: auth_token },
          params: { organization_id: organization_id, user_role_id: 1 },
        })
        .then((response) => {
          if (response.data.error) {
            Snackbar.error(response.data.error)
          } else {
            this.user_list = response.data.user_name_list
          }
        })
    } catch (e) {
      console.log(e)
    }
  },
  methods: {
    getSubjects(name) {
      try {
        return this.journalItems.account_title.find((item) => item.name == name)
          .subjects
      } catch {
        return []
      }
    },
  },
}
</script>
