<template>
  <v-dialog v-model="dialog" max-width="650">
    <v-card>
      <v-card-title v-text="'銀行休日あり'" />

      <v-card-text>
        <v-form>
          <div class="pa-4">
            銀行休日が含まれている可能性があります。このまま振込データ出力を行いますか？
          </div>
          <div class="px-4">
            <div v-for="holiday in holidays" :key="holiday">
              <s-chip-dark class="tw-bg-yellow-500">
                {{ holiday }}
              </s-chip-dark>
            </div>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click.native="close">
          {{ $t('message.modal_cancel_btn') }}
        </v-btn>

        <v-btn color="primary" :loading="loading" @click.native="submit">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      holidays: [],
      loading: false,
    }
  },
  methods: {
    open({ holidays }) {
      this.stopload()
      this.holidays = holidays
      this.dialog = true
    },
    close() {
      this.stopload()
      this.holidays = []
      this.dialog = false
    },
    startload() {
      this.loading = true
    },
    stopload() {
      this.loading = false
    },
    submit() {
      this.close()
      this.$emit('submit')
    },
  },
}
</script>
